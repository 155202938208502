/**
 * Componente ICard:
 *
 * acá se muestran los productos "Te podría interesar"
 *
 * @module ComponenteICardjsx
 */

import { useEffect, useState } from "react";
import "./novedadescards.css";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

import { useSelector, useDispatch } from "react-redux";
import { setAddToCart } from "../../redux/AddToCartSlice";

SwiperCore.use([Navigation, Autoplay]);

const NovedadesCards = () => {
  /**
   * @constant
   * @name novedades
   * @type {Array}
   *
   * */

  const [novedades, setNovedades] = useState([]);

  /**
   * @constant
   * @name userAlready
   * @type {string}
   *
   * */

  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  const codigo_envio = useSelector((state) => state.tipoEnvio);
  const fecha_entrega = useSelector((state) => state.fechaEntrega);
  const addToCart = useSelector((state) => state.addToCart);
  const dispatch = useDispatch();

  /**
   * @function
   * Petición HTTP que obtiene los productos de "Te podría interesar"
   *@returns {Array}
   *
   */
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACK_URL +
          "/categoria/carrusel-te-podria-interesar"
      )
      .then((res) => {
        setNovedades(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  /**
   * @function
   * Petición HTTP que registra dentro del carro un producto por su SKU
   *@returns {Array}
   *
   */
  const handleAddToCart = (value) => {
    axios
      .post(
        process.env.REACT_APP_BACK_URL +
          "/api/carrocompras/register_carrocompras",
        {
          usuario: userAlready ? userAlready : usuarioId,
          sku: value,
          cantidad: 1,
        }
      )
      .then((response) => {
        sessionStorage.setItem("IDusuario", response.data.usuario);
        setUserAlready(sessionStorage.getItem("IDusuario"));
        dispatch(setAddToCart(addToCart + 1));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="novedades-container">
      <Swiper
        spaceBetween={20}
        navigation
        autoplay={{ delay: 3000 }}
        breakpoints={{
          1000: {
            slidesPerView: 4,
          },
          840: {
            slidesPerView: 1,
          },
        }}
      >
        {novedades.map((novedad) => (
          <SwiperSlide key={novedad.id}>
            <div className="border border-dark-subtle mb-5 text-center px-2 pb-4 pt-3 rounded-3">
              <div className="row d-flex justify-content-end">
                <div className="tag-prod-skunov text-center mx-2 w-auto px-2">
                  SKU {novedad.sku}
                </div>
              </div>

              <div className="fixedwidth mt-2">
                <a
                  className="text-black"
                  href={`/${novedad.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + `${novedad.foto_1}`}
                    alt={novedad.alt}
                    title={novedad.nombre}
                    className="imgwidth border-0"
                  />
                </a>
              </div>
              <div className="container">
                <div className="row text-center">
                  <p className="nombre-marca mx-auto">
                    <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                    {novedad.marca}
                  </p>
                </div>
                <div className="row text-center">
                  <h3 className="text-black mb-0 mt-0 nombre-tarj-prod fw-normal">
                    <a
                      className="text-black"
                      href={`/${novedad.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      {novedad.nombre}
                    </a>
                  </h3>
                </div>
                <div className="row text-center">
                  <p className="fs-5 fw-bold mb-2 mt-2 text-black">
                    {novedad.precio_descuento.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })}
                  </p>
                </div>
                <div className="container">
                  <div className="row mb-4">
                    {/* {codigo_envio === 1 && (
                      <div className="col-12">
                        <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                          {" "}
                          {fecha_entrega}
                        </div>
                      </div>
                    )}

                    {codigo_envio === 2 && (
                      <>
                        <div className="col-6">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                            Envio Gratis
                          </div>
                        </div>
                      </>
                    )}
                    {codigo_envio === 3 && (
                      <div className="col-12">
                        <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                          {" "}
                          {fecha_entrega}
                        </div>
                      </div>
                    )}
                    {codigo_envio === 4 && (
                      <div className="col-12">
                        <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                          {" "}
                          Retiro en tienda
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>

                <a
                  className="text-white"
                  href={`/${novedad.slug}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra">
                    Ver detalles
                  </button>
                </a>
                {/* <button onClick={() => handleAddToCart(novedad.sku)} className="bg-azul text-white rounded-pill px-2 py-1 border-0 fs-14 btn-azul-hover btn-sombra">
                    <img
                      className="imgcarrito"
                      src={imgcarrito}
                      alt="imagen carrito"
                    />{" "}
                    Añadir
                  </button> */}
              </div>

              <div className="novedad-info"></div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default NovedadesCards;
