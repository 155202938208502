/**
 * Página Categoría Padre Página:
 * 
 * acá se muestra las categorias hijas de un padre seleccionado
 * 
 * @module PaginaCategoriaPadrePagejsx
 */


import { useState, useEffect, useRef } from "react";
import "./producpage.css";
import { Helmet } from "react-helmet";

import axios from "axios";
//import Slider from "react-slider";

import { useSelector, useDispatch } from "react-redux";
//import { setBooleanCategoria } from "../redux/esCategoriaSlice";
import { setBoolean } from "../redux/esBusquedaSlice";
//import { setAddToCart } from "../redux/AddToCartSlice";
//import { setCheckStock } from "../redux/checkStockSlice";
import { Categorias } from "../components";
import { SwiperCategorias } from "../components";
//import { current } from "@reduxjs/toolkit";
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../redux/fechaEntregaSlice";
import { setComunaSelected } from "../redux/comunaSelectedSlice";

const CategoriaPadrePage = () => {
  axios.defaults.headers.common["Content-Type"] = "application/json";


  /**
* @constant
* @name tipoEnvio
* @type {number}
* 
*/
  const tipoEnvio = useSelector((state) => state.tipoEnvio);


  /**
* @constant
* @name productos
* @type {Array}
* 
*/
  const [productos, setProductos] = useState([]);

  /**
* @constant
* @name categoria
* @type {string}
* 
*/
  const [categoria, setCategoria] = useState("");

  /**
* @constant
* @name cPadre
* @type {string}
* 
*/
  const [cPadre, setCPadre] = useState("");

  /**
* @constant
* @name order
* @type {string}
* 
*/
  const [order, setOrder] = useState("");

  /**
* @constant
* @name currentPage
* @type {number}
* 
*/
  const [currentPage, setCurrentPage] = useState(1);

  /**
* @constant
* @name totalPages
* @type {number}
* 
*/
  const [totalPages, setTotalPages] = useState(0);

  /**
* @constant
* @name minRange
* @type {number}
* 
*/
  const [minRange, setMinRange] = useState(0);

  /**
* @constant
* @name maxRange
* @type {number}
* 
*/
  const [maxRange, setMaxRange] = useState(0);

  /**
* @constant
* @name msg
* @type {string}
* 
*/
  const [msg, setMsg] = useState("");


  const priceMinMax = useRef([]);

  /**
* @constant
* @name categoriaPadre
* @type {string}
* 
*/
  const [categoriaPadre, setCategoriaPadre] = useState("");
  const categoriaCapitalizada = useRef("");

  /**
* @constant
* @name userAlready
* @type {string}
* 
*/
  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  /**
* @constant
* @name usuarioId
* @type {string}
* 
*/
  const [usuarioId, setUsuarioId] = useState("");
  const [marcaje, setMarcaje] = useState(null);

  const dispatch = useDispatch();


  /**
* @function
* Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
* validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
* @param {string} userAlready Id del usuario
* @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
*@returns {Array}
* 
*/
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);


  /**
* @function
* Metodo que valida un string dado
* @param {string} texto texto para validar
*@returns {boolean}
* 
*/
  const validaQuery = (texto) => {
    let validar = [`"`, `.`, `?`, `=`, `'`, `*`, `<`, `>`, `;`, `(`, `)`, `{`, `}`, `[`, `]`, `,`, `_`, `:`, '`'];
    if (texto != null) {
      for (let i = 0; i < validar.length; i++) {
        if (texto.includes(validar[i]))
          return false;
      }
      return true;
    }
    else
      return false;
  };



  const pathname = window.location.pathname;
  const match = pathname.split("/");
  if (match[1] != categoria) {
    const categoria = validaQuery(match[1]) ? match[1] : "";

    categoriaCapitalizada.current = categoria;

    while (categoriaCapitalizada.current.includes("-"))
      categoriaCapitalizada.current = categoriaCapitalizada.current.replace("-", " ");

    let separar = categoriaCapitalizada.current.split(" ");
    let cur = "";
    for (let i = 0; i < separar.length; i++)
      cur += separar[i].charAt(0).toUpperCase() + separar[i].slice(1).toLowerCase() + " ";
    categoriaCapitalizada.current = cur;
    setCategoria(categoria);
  }

  /**
* @function
* Petición HTTP que trae las categorias que tengan foto y ordenadas desc o asc
* @param {string} order orden en que se mostran las categorias hijas
* @param {Array} match Array dado
*@returns {Array}
* 
*/
  const cargaDatos = () => {

    axios.get(
      process.env.REACT_APP_BACK_URL + `/api/categorias/get_categoria_con_fotos/${match[1]}?order=${order}`
    )
      .then((response) => {
        setProductos(response.data);
        setTotalPages(response.data.pages);
        setCPadre(categoriaPadre);
        if (response.data.mensaje != "" && response.data.mensaje != null)
          setMsg(response.data.mensaje);
        else
          setMsg("");
        let catCap = document.querySelector("#texto-categoria-capitalizada");
        if (catCap.textContent == "" || catCap.textContent == null)
          catCap.textContent = categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase();
        if (minRange != response.data.precio_bajo || maxRange != response.data.precio_alto) {
          priceMinMax.current = ([response.data.precio_bajo, response.data.precio_alto]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    dispatch(setBoolean(false));
  };



  useEffect(() => {
    cargaDatos();
  }, [order]);

  useEffect(() => {
    const urlPath = window.location.pathname;
    const segments = urlPath.split('/').filter(Boolean);
    const categoryas = segments[0];
    axios.get(
      process.env.REACT_APP_BACK_URL + `/api/marcaje/obtener_marcaje_categoria_padre?categoria=${categoryas}`
    )
      .then((response) => {
        setMarcaje(response.data.marcaje);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {marcaje && (
        <Helmet>
          <link rel="canonical" href={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
          {(currentPage != 1 || order) ? (
            <meta name="robots" content="NOINDEX,FOLLOW" />
          ) : (null)}
          {(marcaje.title && marcaje.title != "" && marcaje.title != null) ? (
            <title>{marcaje.title + " | Dreamtec"}</title>
          ):(
            <title>{"Dreamtec"}</title>
          )}
          <meta name="description" content={marcaje.description} />
          <meta property="og:description" content={marcaje.description} />
          <meta property="og:title" content={marcaje.title + " | Dreamtec"} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
        </Helmet>
      )}
      {/* <Helmet>
        <link rel="canonical" href={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
        <title>{categoriaCapitalizada.current + " | Dreamtec"}</title>
        <meta name="description" content={`Compra productos ${categoriaCapitalizada.current}en Dreamtec.cl. Tecnología para Gamers, Oficina y Hogar. Encuentra los mejores precios en tecnología y productos exclusivos. Envíos a todo Chile.`} />
        <meta property="og:title" content={categoriaCapitalizada.current + "| Dreamtec"} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={process.env.REACT_APP_FRONT_URL + "/" + match[1]} />
      </Helmet> */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3 col-xs-12 mb-5">
            <Categorias />
          </div>
          <div className="col-sm-12 col-md-8">
            <div className="row ">
              <div className="col-sm-12 col-md-5 text-start">
                <a
                  className="text-black"
                  href="/"
                  style={{ textDecoration: "none" }}
                >
                  Inicio
                </a>
                <span className="text-black">
                  <i className="fa-solid fa-angle-right px-2"></i>
                </span>
                <a className="text-turq fw-bold" id="texto-categoria-capitalizada">
                  {categoriaCapitalizada.current}
                </a>
              </div>
              <div className="col-sm-12 col-md-7 text-end mt-3 justify-content-end pe-3">
                <div className="row text-end justify-content-end">
                  <div className="col-md-6 col-xs-12">
                    <select
                      className="form-select"
                      aria-label="Orden de productos"
                      onChange={(e) => setOrder(e.target.value)}
                    >
                      <option defaultValue="Seleccione una opción">
                        Seleccione una opción
                      </option>
                      <option value="nombre-asc">NOMBRE DE A - Z</option>
                      <option value="nombre-desc">NOMBRE DE Z - A</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 mx-0 ">
              {msg != "" && (
                <div className="container row justify-content-center bg-grey mb-5">
                  <div className="row mt-3 mb-3 text-center">
                    <div className="col-12">
                      <i className="fas fa-exclamation-circle fs-2 text-turq"></i>
                    </div>
                    <div className="col-12">
                      <span className="text-turq fw-bold fs-5">
                        No encontramos categorías con los filtros seleccionados en:{" "}
                        <span className="text-black">
                          {categoriaCapitalizada.current}{" "}
                        </span>
                      </span>
                    </div>
                  </div>{" "}
                  {(productos != [] && productos?.length > 0) && (
                    <div className="row mt-1 mb-3 text-center">
                      <span className="small pb-0">
                        Puedes revisar estas alternativas que tenemos para tí
                      </span>
                    </div>
                  )}
                </div>
              )}
              {productos != [] && productos?.map((producto) => (
                <>
                  {/* Version Web */}
                  <div
                    className="col-md-3 p-3 my-2 bg-white d-xs-none d-md-block"
                    key={producto.id}
                  >
                    <div className="border border-secondary rounded p-3">
                      <img role="button" loading="lazy" onClick={() => {
                        location.href = `/${categoria}/${producto.nombre}`;
                      }} src={process.env.REACT_APP_FRONT_URL + producto.foto} width={150} className="" alt={producto.alt_foto_1} title={producto.nombre} style={{ maxHeight: "150px", maxWidth: "150px", minHeight: "150px", minWidth: "150px", objectFit: "contain" }} />
                      <div className="text-center" style={{ minHeight: "50px" }}>
                        <span>{producto.nombre_mostrar}</span>
                      </div>
                      <div className="text-center mt-3 mb-1">
                        <button
                          className="col-md-10 border-0 bg-turq text-white fs-16 py-1 rounded-pill btn-turq-hover btn-sombra"
                          onClick={() => {
                            location.href = `/${categoria}/${producto.nombre}`;
                          }}
                        >
                          Ver más</button>
                      </div>
                    </div>
                  </div>


                  {/* Version Movil */}
                  <div
                    className="col-md-3 p-3 my-2 bg-white d-xs-block d-md-none"
                    key={producto.id}
                  >
                    <div className="border border-secondary rounded p-3 justify-content-center">
                      <div className="row">
                        <div className="col-12 text-center">
                          <img role="button" loading="lazy" onClick={() => {
                            location.href = `/${categoria}/${producto.nombre}`;
                          }} src={process.env.REACT_APP_FRONT_URL + producto.foto} height={150} width={150} className="" alt={producto.alt_foto_1} title={producto.nombre} style={{ maxHeight: "150px", maxWidth: "150px", minHeight: "150px", minWidth: "150px", objectFit: "contain" }} />
                        </div>
                      </div>

                      <div className="text-center" style={{ minHeight: "50px" }}>
                        <span>{producto.nombre_mostrar}</span>
                      </div>
                      <div className="row text-center mt-3 mb-1">
                        <div className="col-12">
                          <button
                            className="col-6 border-0 bg-turq text-white fs-16 py-1 rounded-pill btn-turq-hover btn-sombra"
                            onClick={() => {
                              location.href = `/${categoria}/${producto.nombre}`;
                            }}
                          >
                            Ver más</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3 mb-5 fixed-footer">
        <div className="row">
          <div className="col-12 text-center">
            {totalPages && (
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className="page-item">
                      <a
                        className={`page-link page-numbers ${index + 1 === currentPage ? "pagination-active" : ""
                          }`}
                        href={`/${categoria}?page=${index + 1}&order=${order}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(index + 1);
                          document.documentElement.scrollTop = 0;
                        }}
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
      {/* vista movil */}
      <div className="container d-sm-none d-md-none">



      </div>
      <div className="d-lg-none">
        <SwiperCategorias />
      </div>

      {/* vista movil */}
    </div>
  );
};

export default CategoriaPadrePage;
