/**
 * Componente Carrito:
 * 
 * acá se muestra el listado de productos selecionados previo a 
 * los pasos de compra
 * 
 * @module ComponenteCarritojsx
 */


import { useState, useEffect, useRef } from "react";
import "./carrito.css";
import axios from "axios";
import ReactGA from "react-ga4";

import { useSelector, useDispatch } from "react-redux";
import { setAddToCart } from "../../redux/AddToCartSlice";
import { setCheckStock } from "../../redux/checkStockSlice";
import { setIdCarro } from "../../redux/idCarroSlice";

import { useNavigate } from "react-router-dom";

const Carrito = () => {


  /**
* @constant
* @name cantidadProductos
* @type {number}
* 
* */
  const [cantidadProductos, setCantidadProductos] = useState(0);


  /**
* @constant
* @name productos
* @type {Array}
* 
* */
  const [productos, setProductos] = useState([]);

  /**
* @constant
* @name total
* @type {number}
* 
* */
  const [total, setTotal] = useState(0);

  /**
* @constant
* @name carritoVisible
* @type {boolean}
* 
* */
  const [carritoVisible, setCarritoVisible] = useState(false);

  /**
* @constant
* @name userAlready
* @type {string}
* 
* */
  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  const addToCart = useSelector((state) => state.addToCart);
  const checkStock = useSelector((state) => state.checkStock);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);

  /**
* @function
* Petición HTTP para obtener el carro de compra actuald el usuario
* @param {string} userAlready
* @returns {Array}
* 
*/
  useEffect(() => {
    setUserAlready(sessionStorage.getItem("IDusuario"));

    if (userAlready) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/carrocompras/get_carrocompras/${userAlready}`
        )
        .then((res) => {
          setProductos(res.data.productos);
          setCantidadProductos(res.data.total_cantidad);
          setTotal(res.data.total_precio);
          dispatch(setIdCarro(res.data.id));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [addToCart, userAlready]);

  /**
* @function
* Petición HTTP para eliminar un producto del carro por su ID
* @param {string} id
* 
*/
  const handleDelete = (id) => {
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/carrocompras/delete_carrocompras`, {
        usuario: userAlready,
        producto: id,
      })
      .then((res) => {
        console.log(res);
        setUserAlready(sessionStorage.getItem("IDusuario"));
        dispatch(setAddToCart(addToCart - 1));
        dispatch(setCheckStock(checkStock - 1));
        removeFromCartGa4(id, res.data.todos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeFromCartGa4 = (id, cantidad) => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/ga/eliminarproductoga4?id=${id}&cantidad=${cantidad}`)
      .then((response) => {
        ReactGA.gtag("event", "remove_from_cart", {
          currency: "CLP",
          value: response.data.value,
          items: [{
            item_id: response.data.sku,
            item_name: response.data.nombre,
            item_brand: response.data.marca,
            item_category: response.data.categoria_padre,
            item_category2: response.data.categoria_hija,
            price: response.data.precio,
            quantity: response.data.cantidad
          }]
        });
      })
      .catch((error) => {
        console.log(error)
      })
  };

  /**
* @function
* Metodo que valida si se hizo un clic fuera del elemento
* @param {Element} event
* 
*/
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (carritoVisible && !divRef.current.contains(event.target)) {
        setCarritoVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [carritoVisible]);


  /**
* @function
* Metodo que valida si existe el token Web o no y 
* redigire segun sea el caso
* 
*/

  const irAPagar = () => {
    if (sessionStorage.accessTokenWeb)
      navigate("/paso2");
    else
      navigate("/paso1");
  };

  return (
    <>
      <button
        className="bg-transparent border-0 text-white mb-2"
        id="abrir-resumen"
      >
        <div
          ref={divRef}
          onClick={() => {
            setCarritoVisible(!carritoVisible);
          }}
          className="row justify-content-center align-items-center"
        >
          <div
            className="col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 text-center px-2 mb-1"
            style={{ position: "relative" }}
          >

            <img
              loading="eager"
              src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono-boton-header-carro.png"}
              className="icono-header-circular"
              alt="Carro de compra"
            />

          </div>

          <div className="col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12 text-start px-2">
            <div className="row justify-content-start">

              <div className="col-lg-12 col-md-12 col-12 d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                {cantidadProductos > 0 && (

                  <>
                    <div className="cantidad-productos-carro text-center "> {/* pxsmv */}
                      {cantidadProductos}
                    </div>

                    <div className="col-lg-12 col-md-12 col-12 fs-12 d-flex">

                      <span className="text-white me-1">Total</span>

                      <span className="inner_total fw-bold">
                        {total.toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        })}
                      </span>

                    </div>
                  </>
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-12 text-center">

                {!cantidadProductos && (
                  <span className="text-white d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">Carrito vacío</span>
                )}

              </div>

            </div>
          </div>

        </div>
      </button>
      {carritoVisible && (

        <div className="container position-absolute bg-white w-carrito roundedq boxshadow">
          <div className="row">
            <div className="col-12">
              <ul className="paddingul max-height-2">
                {productos.map((producto) => (
                  <li key={producto.id_producto}>
                    <div className="container mt-2 bg-cream">
                      <div className="row">
                        <div className="col-4 text-start">
                          <span className="div-circulo-detalle-producto">
                            {producto.cantidad}
                          </span>

                          <div className="mt-2 div-white-img">
                            <img
                              loading="eager"
                              src={process.env.REACT_APP_FRONT_URL + `${producto.foto_producto}`}
                              width="100%"
                              alt={producto.name_producto}
                              title={producto.name_producto}
                              className="img-fluid mt-3"
                              onClick={() => {
                                location.href = "/" + producto.slug_producto
                              }}
                            />
                          </div>

                          <button
                            aria-label="Eliminar"
                            className="btn text-turq fs-12"
                            style={{ textDecoration: "underline" }}
                            onClick={() => {
                              handleDelete(producto.id_producto);
                            }}
                          >
                            Eliminar
                          </button>
                        </div>

                        <div className="col-8 mt-5" onClick={() => {
                          location.href = "/" + producto.slug_producto
                        }}>
                          <span className="text-dark">
                            {producto.name_producto
                              ? producto.name_producto.slice(0, 25) + "..."
                              : "Nombre del producto no disponible"}
                          </span>
                          <br />
                          <span className="fw-bold">
                            {producto.precio
                              ? producto.precio.toLocaleString("es-CL", {
                                style: "currency",
                                currency: "CLP",
                              })
                              : "Precio no disponible"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {cantidadProductos > 0 && (
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <span className="">Subtotal </span>
                </div>
                <div className="col-6 fw-bold">
                  {total.toLocaleString("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  })}
                </div>
              </div>
            </div>
          )}

          {cantidadProductos > 0 && (
            <button
              className="btn w-100 mt-3 bg-turq btn-turq-hover text-white rounded-pill py-2 border-0 fs-17 mb-3"
              onClick={() => { irAPagar() }}
            >
              Ir a pagar
            </button>
          )}

          {cantidadProductos > 0 && (
            <div className="text-center mb-2">
              <span
                className="btn text-turq fs-14"
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  setCarritoVisible(!carritoVisible);
                }}
              >
                Volver a la tienda
              </span>
            </div>
          )}

          {!cantidadProductos && (
            <div className="container text-center">
              <div className="row">
                <div className="col-12">
                  <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/icono-circulo-carro.png"} alt="" className="img-fluid" loading="eager" />
                  <label className="text-turq fw-bold">
                    Tu carro está vacio
                  </label>
                </div>
              </div>
            </div>
          )}

        </div>

      )}
    </>
  );
};

export default Carrito;
