/**
 * Página Admin Marcaje Banners:
 *
 * acá se crean o modifica el marcaje de los banners.
 *
 * @module AdminMarcajeBannersjsx
 */

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const MarcajeBanners = (props) => {
  const navigate = useNavigate();

  const [mensaje, setMensaje] = useState("");
  const [msg, setMsg] = useState("");
  const [tipo, setTipo] = useState("");
  const accessToekn = sessionStorage.getItem("access");
  const [banners, setBanners] = useState([]);
  const [bannersMovil, setBannersMovil] = useState([]);
  const [alt, setAlt] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/banners/get_all_banners`)
      .then((response) => {
        setBanners(response.data.banners_web);
        setBannersMovil(response.data.banners_movil);

        // Inicializar el estado `alt` con los valores de `alt` de los banners
        const initialAlt = [
          ...response.data.banners_web.map((banner) => banner.alt || ""),
          ...response.data.banners_movil.map((banner) => banner.alt || ""),
        ];
        setAlt(initialAlt);
      })
      .catch((error) => console.log(error));
  }, []);

  const validateAlt = () => {
    const validarHTML = /<\/?[a-z][\s\S]*>/i;
    let isValid = true;

    // Validar alts de banners escritorio
    banners.forEach((banner, index) => {
      if (validarHTML.test(alt[index] || "")) {
        setMensaje(
          `El campo Alt del banner de escritorio ${
            index + 1
          } contiene HTML-rojo`
        );
        setTipo(2);
        isValid = false;
      }
    });

    // Validar alts de banners móvil
    bannersMovil.forEach((banner, index) => {
      if (validarHTML.test(alt[index + banners.length] || "")) {
        setMensaje(
          `El campo Alt del banner móvil ${index + 1} contiene HTML-rojo`
        );
        setTipo(2);
        isValid = false;
      }
    });

    return isValid;
  };

  const handleGuardar = async () => {
    if (!validateAlt()) {
      return;
    }

    // Construir el array de banners
    const bannersFinal = banners.map((banner, index) => ({
      id: banner.id,
      alt: alt[index] || "",
    }));

    const bannersMovilFinal = bannersMovil.map((banner, index) => ({
      id: banner.id,
      alt: alt[index + banners.length] || "",
    }));

    const dataToSend = { banners: [...bannersFinal, ...bannersMovilFinal] };


    // Enviar al backend
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/marcaje/marcaje_banner_silder`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMensaje("Banners actualizados correctamente-verde");
      setTipo(1);
    } catch (error) {
      console.error(
        "Error en la solicitud:",
        error.response?.data || error.message
      );
      setMensaje(error.response?.data?.mensaje || "Error inesperado-rojo");
      setTipo(2);
    }
  };

  return (
    <div>
      <AdminSidebar />
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />

      <div className="regular">
        <h1>
          Dreamtec - <span>Administrador de marcaje banners</span>
        </h1>
        <div className="shadow">
          <div className="row">
            <div className="col-12 mt-5">
              <div className="col-12 text-start my-3">
                <h2>Banners Escritorio</h2>
              </div>
              {banners.map((foto, index) => (
                <div className="row" key={index}>
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                    <img
                      src={`${process.env.REACT_APP_FRONT_URL}${foto.foto}`}
                      width={200}
                      height={100}
                      className="img-fluid"
                      alt={
                        alt[index] || `Alt no definido para banner ${index + 1}`
                      }
                    />
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                    <label className="fw-bold mx-1 form-label">
                      Alt banner {index + 1}:
                    </label>
                    <input
                      className="form-control"
                      placeholder={`Alt para el banner ${index + 1}`}
                      type="text"
                      value={alt[index] || ""}
                      required
                      onChange={(e) => {
                        const newAlt = [...alt];
                        newAlt[index] = e.target.value;
                        setAlt(newAlt);
                      }}
                    />
                  </div>
                </div>
              ))}

              <div className="col-12 text-start my-3">
                <h2>Banners Móvil</h2>
              </div>

              {bannersMovil.map((foto, index) => (
                <div className="row" key={index}>
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                    <img
                      src={`${process.env.REACT_APP_FRONT_URL}${foto.foto}`}
                      width={200}
                      height={100}
                      className="img-fluid"
                      alt={
                        alt[index + banners.length] ||
                        `Alt no definido para banner móvil ${index + 1}`
                      }
                    />
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                    <label className="fw-bold mx-1 form-label">
                      Alt banner móvil {index + 1}:
                    </label>
                    <input
                      className="form-control"
                      placeholder={`Alt para el banner móvil ${index + 1}`}
                      type="text"
                      value={alt[index + banners.length] || ""}
                      required
                      onChange={(e) => {
                        const newAlt = [...alt];
                        newAlt[index + banners.length] = e.target.value;
                        setAlt(newAlt);
                      }}
                    />
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
                  <button
                    aria-label="Guardar"
                    className="btnGreen w-100"
                    type="button"
                    onClick={handleGuardar}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarcajeBanners;
