/**
 * Página Admin Personal:
 *
 * acá se Adminsitra el personal
 *
 * @module AdminPersonaljsx
 */

import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const Personal = () => {
  /**
   * @constant
   * @name showPass
   * @type {boolean}
   *
   * */

  const [showPassPersonal, setShowPassPersonal] = useState(false);

  /**
   * @constant
   * @name showPass2Personal
   * @type {boolean}
   *
   * */

  const [showPass2Personal, setShowPass2Personal] = useState(false);

  /**
   * @constant
   * @name passWord
   * @type {string}
   *
   * */

  const [passWordPersonal, setPasswordPersonal] = useState("");

  /**
   * @constant
   * @name passWord2
   * @type {string}
   *
   * */

  const [passWordPersonal2, setPasswordPersonal2] = useState("");

  const [mensajeValidacion, setMensajeValidacion] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(null);

  const [selectedUserName, setSelectedUserName] = useState("");

  const [mensajeError, setMensajeError] = useState("");

  /**
   * Valida si la clave cumple con los requisitos de seguridad.
   * - Mínimo 8 caracteres.
   * - Al menos una letra mayúscula.
   * - Al menos una letra minúscula.
   * - Al menos un número.
   * - Al menos uno de los siguientes símbolos especiales: . , _ @ ! * ;
   *
   * @param {string} clave - Contraseña a validar.
   * @returns {boolean} - True si la contraseña es válida, false en caso contrario.
   */
  const validaClave = (clave) => {
    const requisitos = {
      longitud: clave.length >= 8,
      mayuscula: /[A-Z]/.test(clave),
      minuscula: /[a-z]/.test(clave),
      numero: /[0-9]/.test(clave),
      simbolo: /[.,_@!*;]/.test(clave),
    };

    if (!requisitos.longitud) {
      setMensajeError("La clave debe tener al menos 8 caracteres.");
      return false;
    }
    if (!requisitos.mayuscula) {
      setMensajeError("Debe incluir al menos una letra mayúscula.");
      return false;
    }
    if (!requisitos.minuscula) {
      setMensajeError("Debe incluir al menos una letra minúscula.");
      return false;
    }
    if (!requisitos.numero) {
      setMensajeError("Debe incluir al menos un número.");
      return false;
    }
    if (!requisitos.simbolo) {
      setMensajeError(
        "Debe incluir alguno de estos caracteres especiales: . , _ @ ! * ;"
      );
      return false;
    }

    setMensajeError("");
    return true;
  };

  const handlePasswordChange = (e) => {
    setPasswordPersonal(e.target.value);
    if (passWordPersonal2 && e.target.value !== passWordPersonal2) {
      setMensajeValidacion("Las contraseñas no coinciden.");
    } else {
      setMensajeValidacion("");
    }
  };

  const handlePasswordConfirmChange = (e) => {
    setPasswordPersonal2(e.target.value);
    if (passWordPersonal && e.target.value !== passWordPersonal) {
      setMensajeValidacion("Las contraseñas no coinciden.");
    } else {
      setMensajeValidacion("");
    }
  };

  /**
   * @function
   * Petición HTTP para cambiar la contraseña
   * @param {Object} formData formData con todos los datos del usuario para cambiar la contraseña
   */

  // Manejo del submit
  const handleSubmitCambiarPass = (e) => {
    e.preventDefault();

    if (!validaClave(passWordPersonal)) {
      return;
    }

    if (passWordPersonal !== passWordPersonal2) {
      setMensajeValidacion("Las contraseñas no coinciden.");
      return;
    }

    const formData = new FormData();
    formData.append("id", selectedUserId);
    formData.append("clave1", passWordPersonal);
    formData.append("clave2", passWordPersonal2);

    setMensaje("Cambiando contraseña-alert");

    axios
      .post(
        process.env.REACT_APP_BACK_URL +
          "/api/admin/cambiar_clave_personal_admin",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        }
      )
      .then((response) => {
        setMensaje(response.data + "-verde");
        setTipo(1);

        setTimeout(() => {
          setSelectedUserId("id");
          setSelectedUserName("");
          setIsModalOpen(false);
          setPasswordPersonal("");
          setPasswordPersonal2("");
          setMensajeError("");
          setMensajeValidacion("");
        }, 2500);
      })
      .catch((error) => {
        setMensaje(error + "-rojo");
        setTipo(2);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);
      });
  };
  /**
   * @constant
   * @name firstName
   * @type {string}
   *
   * */

  const [firstName, setFirstName] = useState("");

  /**
   * @constant
   * @name lastName
   * @type {string}
   *
   * */

  const [idUsuario, setIdUsuario] = useState("");

  /**
   * @constant
   * @name idUsuario
   * @type {string}
   *
   * */

  const [lastName, setLastName] = useState("");

  /**
   * @constant
   * @name email
   * @type {string}
   *
   * */

  const [email, setEmail] = useState("");

  /**
   * @constant
   * @name opciones
   * @type {Array}
   *
   * */
  const [opciones, setOpciones] = useState([]);

  /**
   * @constant
   * @name opcioneopcionSeleccionadas
   * @type {string}
   *
   * */
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("");

  /**
   * @constant
   * @name password
   * @type {string}
   *
   * */
  const [password, setPassword] = useState("");

  /**
   * @constant
   * @name password2
   * @type {string}
   *
   * */
  const [password2, setPassword2] = useState("");

  /**
   * @constant
   * @name personal
   * @type {Array}
   *
   * */
  const [personal, setPersonal] = useState([]);

  /**
   * @constant
   * @name agregar
   * @type {string}
   *
   * */
  const [agregar, setAgregar] = useState("");

  /**
   * @constant
   * @name msg
   * @type {string}
   *
   * */
  const [msg, setMsg] = useState("");

  /**
   * @constant
   * @name tipo
   * @type {string}
   *
   * */

  const [busqueda, setBusqueda] = useState("");

  /**
   * @constant
   * @name busqueda
   * @type {string}
   *
   * */

  const [tipo, setTipo] = useState("");

  /**
   * @constant
   * @name currentPage
   * @type {number}
   *
   * */
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * @constant
   * @name showPass
   * @type {boolean}
   *
   * */

  const [showPass, setShowPass] = useState(false);

  /**
   * @constant
   * @name showPass1
   * @type {boolean}
   *
   * */
  const [showPass1, setShowPass1] = useState(false);

  /**
   * @constant
   * @name totalPaginas
   * @type {string}
   *
   * */
  const [totalPaginas, setTotalPaginas] = useState("");

  /**
   * @constant
   * @name mensaje
   * @type {string}
   *
   * */
  const [mensaje, setMensaje] = useState("");

  const togglePasswordShow = () => {
    setShowPass((current) => !current);
  };

  const togglePasswordShow1 = () => {
    setShowPass1((current) => !current);
  };

  const accessToekn = sessionStorage.getItem("access");

  useEffect(() => {
    obtenerUsuarios();
  }, [agregar, currentPage]);

  /**
   * @function
   * Petición HTTP que lista los roles
   * @returns {Array}
   */

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/admin/listar_roles", {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      })
      .then((response) => {
        setOpciones(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  /**
   * @function
   * Petición HTTP que lista todos los usuarios personal
   * @param {number} currentPage Pagina Actual
   * @returns {Array}
   */
  const obtenerUsuarios = async () => {
    const response = await axios
      .get(
        process.env.REACT_APP_BACK_URL +
          `/api/admin/listar_all_usuarios_personal?page=${currentPage}&size=10`,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        }
      )
      .then((response) => {
        setPersonal(response.data.results);
        setTotalPaginas(response.data.pages);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const sanitizeInput = (input) => {
    // Crear un elemento temporal para filtrar contenido HTML
    const tempDiv = document.createElement('div');
    tempDiv.textContent = input; // Esto elimina cualquier etiqueta HTML al tratarlo como texto
    return tempDiv.innerHTML.trim(); // Eliminar espacios adicionales
};

  const handleSubmitBuscarPersonal = (e) => {
    e.preventDefault();

    const sanitizedBusqueda = sanitizeInput(busqueda);

    if (sanitizedBusqueda !== "") {

      const response = axios
        .get(
          process.env.REACT_APP_BACK_URL +
            `/api/admin/buscar_usuario_personal?page=${currentPage}&size=10&busqueda=${busqueda}`,
          {
            headers: {
              Authorization: `Bearer ${accessToekn}`,
            },
          }
        )
        .then((response) => {
          setPersonal(response.data.results);
          setTotalPaginas(response.data.pages);
        })
        .catch((error) => {
          console.log(error);
        });

    } else {
      setMensaje("Debe ingresar un campo a buscar-rojo");
      setTipo(2);
      setTimeout(() => {
        setMensaje("");
        setMsg("");
        setTipo("");
      }, 2000);
    }
  };

  /**
   * @function
   * Petición HTTP que registra el usuario personal
   * @param {Object} formData FormData con todos los datos para ingresar el personal
   * @returns {string}
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("password1", password);
    formData.append("password2", password2);
    formData.append("email", email);
    formData.append("rol", opcionSeleccionada);

    setMensaje("Registrando personal-alert");
    axios
      .post(
        process.env.REACT_APP_BACK_URL +
          "/api/admin/registrar_usuario_personal",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        }
      )
      .then((response) => {
        setMsg(response.data);
        setTipo(1);

        setFirstName("");
        setLastName("");
        setPassword("");
        setPassword2("");
        setEmail("");
        setMensaje("Usuario registrado-verde");
        location.href = "/admin/personal";
        if (msg === "Usuario registrado correctamente") {
          setAgregar(false);
          setMensaje("Usuario registrado-verde");
          setMsg("");
          setTipo(0);
        }
      })
      .catch((error) => {
        setMensaje("Error al registrar el personal-rojo");

        setMsg("No se pudo agregar personal");
        setTipo(2);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);
      });
  };

  const handleConfirmarPassword = (id, name) => {
    setSelectedUserId(id);
    setSelectedUserName(name);
    setIsModalOpen(true);
    setPasswordPersonal("");
    setPasswordPersonal2("");
    setMensajeError("");
    setMensajeValidacion("");
  };

  const handleShowPassword = () => {
    setShowPassPersonal((prev) => !prev); // Alternar entre true y false
  };

  const handleShowPassword2 = () => {
    setShowPass2Personal((prev) => !prev);
  };

  return (
    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />

      <div className="regular">
        <h1>
          Dreamtec - <span>Administrador de Personal</span>
        </h1>

        <div className="shadow">
          <div className="row">
            <div className="col-12">
              <div className="row">
                {!agregar && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 text-center my-3">
                        <h2>Listado personal</h2>
                      </div>
                    </div>
                    <form onSubmit={handleSubmitBuscarPersonal}>
                      <div className="row justify-content-center">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
                          <input
                            placeholder="Busca por Nombre, Apellido o Correo"
                            className="form-control rounded-pill"
                            aria-label="Filtrar por Nombre, Apellido o Correo"
                            value={busqueda}
                            onChange={(e) => {
                              setBusqueda(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                          <button
                            type="submit"
                            className="agregar"
                            aria-label="Buscar"
                            title="Buscar"
                          >
                            Buscar
                          </button>
                        </div>
                      </div>
                    </form>

                    <div>
                      {personal.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table boder">
                            <thead className="p-4">
                              <tr>
                                <th scope="col">
                                  <div className="id text-center">ID</div>
                                </th>
                                <th scope="col">
                                  <div className="id text-center">Nombre</div>
                                </th>
                                <th scope="col">
                                  <div className="id text-center">Apellido</div>
                                </th>
                                <th scope="col">
                                  <div className="id text-center">Email</div>
                                </th>
                                <th scope="col">
                                  <div className="id text-center">Rol</div>
                                </th>
                                <th scope="col">
                                  <div className="id text-center">
                                    Fecha registro
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="id text-center">Acciones</div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {personal?.map((usuario) => (
                                <tr key={usuario.id}>
                                  <th scope="row">
                                    <div className="number text-center">
                                      {usuario.id}
                                    </div>
                                  </th>
                                  <td>
                                    <div className="nameNumber text-center">
                                      {usuario.first_name}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="nameNumber text-center">
                                      {usuario.last_name != " "
                                        ? usuario.last_name
                                        : "N/A"}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="nameNumber text-center">
                                      {usuario.email}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="nameNumber text-center">
                                      {usuario.rol}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="nameNumber text-center">
                                      {usuario.fecha_registro}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="nameNumber text-center">
                                      <button
                                        class="agregar"
                                        title="Cambiar Contraseña"
                                        aria-label="Cambiar Contraseña"
                                        onClick={() => {
                                          handleConfirmarPassword(
                                            usuario.id,
                                            `${usuario.first_name} ${usuario.last_name}`
                                          );
                                        }}
                                      >
                                        Cambiar Contraseña
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          {isModalOpen && (
                            <div
                              className="modal fade show"
                              style={{
                                display: "block",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-header border-bottom-0">
                                    <button
                                      type="button"
                                      className="btn-close"
                                      onClick={() => setIsModalOpen(false)}
                                    ></button>
                                  </div>
                                  <form onSubmit={handleSubmitCambiarPass}>
                                    <div className="modal-body">
                                      <div className="container">
                                        <div className="row justify-content-center">
                                          <div class="col-lg-10 col-md-11 col-11 mb-4 text-center">
                                            <span class="text-black fs-6 fw-bold">
                                              Cambiar Contraseña Para{" "}
                                              {selectedUserName}
                                            </span>
                                          </div>
                                          <div className="col-lg-10 col-md-11 col-11 mb-3">
                                            <label>
                                              Contraseña nueva&nbsp;
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>{" "}
                                            <div className="input-group">
                                              <input
                                                type={
                                                  !showPassPersonal
                                                    ? "password"
                                                    : "text"
                                                } // Alternar entre 'password' y 'text'
                                                className="form-control"
                                                value={passWordPersonal}
                                                onChange={handlePasswordChange}
                                                required
                                              />
                                              <span
                                                role="button"
                                                className="input-group-text border-start-0 border-radius-right boton-ver-pass-login"
                                                onClick={handleShowPassword} // Llamar la función corregida
                                              >
                                                <i
                                                  className={
                                                    !showPassPersonal
                                                      ? "fa-sharp fa-solid fa-eye-slash pe-none"
                                                      : "fas fa-eye pe-none"
                                                  }
                                                ></i>
                                              </span>
                                            </div>
                                            <span style={{ fontSize: "12px" }}>
                                              Mínimo 8, mayúsculas, minúsculas,
                                              números y símbolos: . , _ @ ! * ;
                                              <span class="text-danger">*</span>
                                            </span>
                                          </div>

                                          <div className="col-lg-10 col-md-11 col-11 mb-3">
                                            <div className="mb-3">
                                              <label>
                                                Contraseña confirmación&nbsp;
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <div className="input-group">
                                                <input
                                                  type={
                                                    !showPass2Personal
                                                      ? "password"
                                                      : "text"
                                                  } // Alternar entre 'password' y 'text'
                                                  className="form-control"
                                                  value={passWordPersonal2}
                                                  onChange={
                                                    handlePasswordConfirmChange
                                                  }
                                                  required
                                                />
                                                <span
                                                  role="button"
                                                  className="input-group-text border-start-0 border-radius-right boton-ver-pass-login"
                                                  onClick={handleShowPassword2} // Llamar la función corregida
                                                >
                                                  <i
                                                    className={
                                                      !showPass2Personal
                                                        ? "fa-sharp fa-solid fa-eye-slash pe-none"
                                                        : "fas fa-eye pe-none"
                                                    }
                                                  ></i>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {mensajeError && (
                                          <div className="alert alert-danger">
                                            {mensajeError}
                                          </div>
                                        )}

                                        {mensajeValidacion && (
                                          <div className="alert alert-warning">
                                            {mensajeValidacion}
                                          </div>
                                        )}
                                      </div>
                                      <div className="container pb-4">
                                        <div className="row justify-content-center">
                                          <div className="col-lg-7 col-md-7 col-sm-6 col-7 text-center">
                                            <button
                                              title="Cambiar contraseña"
                                              aria-label="Cambiar Contraseña"
                                              type="submit"
                                              className="btnGreen mx-2"
                                            >
                                              Cambiar Contraseña
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="row justify-content-end mb-3">
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                              {currentPage > 1 && (
                                <button
                                  type="button"
                                  aria-label="Anterior"
                                  className="agregar border-0 btnCelesteDreamtec"
                                  onClick={() =>
                                    setCurrentPage(currentPage - 1)
                                  }
                                >
                                  <i class="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                                </button>
                              )}
                            </div>

                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                              {currentPage + 1 <= totalPaginas && (
                                <button
                                  type="button"
                                  aria-label="Siguiente"
                                  className="agregar border-0 btnCelesteDreamtec"
                                  onClick={() =>
                                    setCurrentPage(currentPage + 1)
                                  }
                                >
                                  <i class="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row my-5">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                            <i className="fa-solid fa-circle-info"></i>&nbsp;No
                            hay personal registrado
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className={
                        personal.length > 0
                          ? "row justify-content-end mt-3"
                          : "row justify-content-end "
                      }
                    >
                      <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button
                          onClick={() => {
                            setAgregar(true);
                          }}
                          className="agregar"
                        >
                          <i className="fa-solid fa-circle-plus"></i> &nbsp;
                          Agregar personal
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {agregar && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 text-start mb-3">
                        <h2 className="">Agregar personal</h2>
                      </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                          <label>
                            Nombre: &nbsp;<span className="text-danger">*</span>
                          </label>

                          <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Ingresa nombre"
                          />
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                          <label>
                            Apellido: &nbsp;
                            <span className="text-danger">*</span>
                          </label>

                          <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Ingresa apellido"
                          />
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label>
                            Email: &nbsp;<span className="text-danger">*</span>
                          </label>

                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Ingresa email"
                          />
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label>
                            Rol: &nbsp;<span className="text-danger">*</span>
                          </label>

                          <select
                            value={opcionSeleccionada}
                            onChange={(e) =>
                              setOpcionSeleccionada(e.target.value)
                            }
                            className="form-control rounded-pill"
                            required
                          >
                            <option value="">Seleccione una opción</option>
                            {opciones.map((opcion) => (
                              <option key={opcion.id} value={opcion.id}>
                                {opcion.nombre}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label>
                            Contraseña: &nbsp;
                            <span className="text-danger">*</span>
                          </label>

                          <div className="input-group">
                            <input
                              type={!showPass1 ? "password" : "text"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                              minLength={8}
                              required
                              placeholder="********"
                            />
                            <span
                              role="button"
                              className="input-group-text border-start-0 border-radius-right boton-ver-pass-login"
                              id="show_password"
                              onClick={togglePasswordShow1}
                            >
                              <i
                                className={
                                  !showPass1
                                    ? "fa-sharp fa-solid fa-eye-slash"
                                    : "fas fa-eye"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label>
                            Confirmar contraseña: &nbsp;
                            <span className="text-danger">*</span>
                          </label>

                          <div className="input-group">
                            <input
                              type={!showPass ? "password" : "text"}
                              value={password2}
                              onChange={(e) => setPassword2(e.target.value)}
                              className="form-control"
                              minLength={8}
                              required
                              placeholder="********"
                            />

                            <span
                              role="button"
                              className="input-group-text border-start-0 border-radius-right boton-ver-pass-login"
                              id="show_password"
                              onClick={togglePasswordShow}
                            >
                              <i
                                className={
                                  !showPass
                                    ? "fa-sharp fa-solid fa-eye-slash"
                                    : "fas fa-eye"
                                }
                              ></i>
                            </span>
                          </div>

                          {password !== password2 && (
                            <p style={{ color: "red" }}>
                              Las contraseñas no coinciden
                            </p>
                          )}

                          {password === password2 && password !== "" && (
                            <p style={{ color: "green" }}>
                              Las contraseñas coinciden
                            </p>
                          )}
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <button
                            aria-label="Agregar personal"
                            className="btnGreen w-100"
                            type="submit"
                          >
                            Agregar personal
                          </button>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <button
                            type="button"
                            aria-label="Volver al listado"
                            className="cancel w-100"
                            onClick={() => {
                              setAgregar(false);
                            }}
                          >
                            Volver al listado
                          </button>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 my-3 text-center">
                          <span
                            className={
                              tipo == 1
                                ? "text-success fw-bold"
                                : "text-danger fw-bold"
                            }
                          >
                            {msg}
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personal;
