/**
 * Página Compras Anteriores:
 * 
 * acá se el listado de las compras anteriores del usario logueado
 * 
 * @module PaginaComprasAnterioresjsx
 */

import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ComprasAnteriores.css";
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../redux/fechaEntregaSlice";
import { setComunaSelected } from "../redux/comunaSelectedSlice";
import { useSelector, useDispatch } from "react-redux";


const ComprasAnteriores = () => {


  /**
* @constant
* @name compras
* @type {Array}
* 
* */

  const [compras, setCompras] = useState([]);

  /**
* @constant
* @name token
* @type {string}
* 
* */
  const token = sessionStorage.getItem("accessTokenWeb");

  /**
* @constant
* @name show
* @type {boolean}
* 
* */
  const [show, setShow] = useState(false);


  /**
* @constant
* @name modal_body
* @type {string}
* 
* */

  const [modal_body, setModalBody] = useState('');

  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  /**
* @constant
* @name userAlready
* @type {string}
* 
* */
  const [userAlready] = useState(sessionStorage.getItem("IDusuario"));

  /**
* @constant
* @name tipoEnvio
* @type {number}
* 
* */
  const tipoEnvio = useSelector((state) => state.tipoEnvio);

  const dispatch = useDispatch();

  /**
* @function
* Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
* validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
* @param {string} userAlready Id del usuario
* @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
*@returns {Array}
* 
*/
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);

  const handleClose = () => {
    setModalBody('');
    setShow(false);
  }

  /**
* @function
* Petición HTTP que trae las compras anteriores del usuario logueado
* @param {string} token token del usuarios logueado
* @param {json} config json con la configuración de la petición HTTP
*@returns {Array}
* 
*/
  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/compra/mis_compras", config)
      .then((response) => {
        setCompras(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  /**
* @function
* Metodo que muestra el detalle de la compra selecionada en un modal
* @param {Array} detalle_compra detalle de la compra pasada por medio de un botón
* 
*/

  const mostrarDetalleCompra = (detalle_compra) => {
    let detalles = detalle_compra;
    let dc = '';

    detalles.map((index) => {
      let row = '';

      row = `
    
        <div className="row justify-content-center text-center d-xs-none d-md-flex">
            
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center align-self-center">
                <img loading="lazy" alt="${index.nombre}" title="${index.nombre}" width="100px" height="auto" src="${process.env.REACT_APP_FRONT_URL + index.foto}" aria-label="${index.nombre}">
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center align-self-center">
                ${index.nombre}
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center align-self-center">
                ${index.cantidad}
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center align-self-center">
                $${Intl.NumberFormat('es-CL').format(index.precio_unitario * index.cantidad)}
            </div>

            <div className="col-10 text-center">
              <hr>
            </div>

        </div>

        <div className="row d-xs-block d-md-none">
            
            <div className="col-3 align-self-center">
                <img loading="lazy" alt="${index.nombre}" title="${index.nombre}" className='img-fluid' height="auto" src="${process.env.REACT_APP_FRONT_URL + index.foto}" aria-label="${index.nombre}">
            </div>
            <div className="col-4 text-break align-self-center" style="font-size:12px">
                ${index.nombre}
            </div>
            <div className="col-2 align-self-center text-center" style="font-size:12px">
                ${index.cantidad}
            </div>
            <div className="col-3 align-self-center text-center" style="font-size:12px">
                $${Intl.NumberFormat('es-CL').format(index.precio_unitario * index.cantidad)}
            </div>

            <div className="col-10 text-center">
              <hr>
            </div>

        </div>
      
        `;

      dc += row;

    })

    setModalBody(dc);

    handleShow();
  };

  return (
    <div>

      <Modal show={show} onHide={handleClose} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          {/* Version Web */}
          <div className="container d-xs-none d-md-block">
            <div className="row justify-content-center mb-5">

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center">
                <strong>Foto</strong>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center">
                <strong>Nombre</strong>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center">
                <strong>Cantidad</strong>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12 text-center">
                <strong>Sub total</strong>
              </div>
            </div>
          </div>

          {/* Version Movil */}
          <div className="container d-xs-block d-md-none">
            <div className="row mb-5">
              <div className="col-3 text-center">
                <strong>Foto</strong>
              </div>

              <div className="col-3 text-center">
                <strong>Nombre</strong>
              </div>

              <div className="col-3 text-center">
                <strong>Cantidad</strong>
              </div>

              <div className="col-3 text-center">
                <strong>Sub total</strong>
              </div>
            </div>
          </div>

          <div className=" container" >
            {modal_body != '' ? (
              parse(modal_body)
            ) : (

              <div className="row my-5">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                  <i className="fa-solid fa-circle-info"></i>&nbsp;No hay detalle del pedido
                </div>
              </div>

            )}
          </div>

        </Modal.Body>
      </Modal>

      {" "}

      <div className="container px-0 mt-5">
        <div className="row px-1">
          <div className="col-md-12 mb-3">
            <a className="fs-14 text-black" href="/">
              Inicio
            </a>
            <span className="text-black">
              <i className="fa-solid fa-angle-right px-2 fs-12"></i>
            </span>
            <span className="fs-14 text-black">Mi cuenta</span>
            <span className="text-black">
              <i className="fa-solid fa-angle-right px-2 fs-12"></i>
            </span>
            <span className="fw-bold fs-18 text-turq">Ver mis compras</span>
          </div>
        </div>

        <hr />

        <h1 className="text-black fw-bold my-4 text-center">
          MIS COMPRAS ANTERIORES
        </h1>
      </div>

      <div className="container mt-4 mb-4">
        {compras ? (
          compras.map((compra, index) => (
            <>
              {/* Version Web */}
              <div className="container border bg-light mt-2 rounded-top text-secondary d-xs-none d-md-block" key={compra.id}>
                <div className="row p-2">
                  <div className="col-md-3">
                    <span className="fs-12">FECHA DEL PEDIDO</span>
                    <br />
                    <span className="fs-14"><strong>{compra.fecha}</strong></span>
                  </div>
                  <div className="col-md-2">
                    <span className="fs-12">TOTAL</span>
                    <br />
                    <span className="fs-14"><strong>{"$" + compra.total.toLocaleString("es-CL")}</strong></span>
                  </div>
                  <div className="col-md-3 text-start">
                    <span className="fs-12">{compra.direccion == "Dreamtec Tienda" ? "ENTREGAR A" : "ENVIAR A"}</span>
                    <br />
                    <span className="fs-14"><strong>{compra.usuario.replace(".zzzzz.", "")}</strong></span>
                  </div>
                  <div className="col-md-4 text-end">
                    <span className="fs-12">NUMERO PEDIDO: {compra.id}</span>
                    <br />
                    {compra.link_boleta && compra.link_boleta != "" ? <span><a href={compra.link_boleta} target="_blank" style={{ textDecoration: "none" }}>Ver Comprobante</a></span> : ""}

                  </div>
                </div>
              </div>


              <div className="container border mb-4 rounded-bottom d-xs-none d-md-block" key={compra.id + "-" + compra.id}>
                <div className="row ps-2 pe-2">
                  <div className="col-md-2 text-center">
                    <img loading="lazy" alt={compra.productos[0].nombre} title={compra.productos[0].nombre} src={process.env.REACT_APP_FRONT_URL + compra.productos[0].foto} className="img-fluid" />
                  </div>
                  <div className="col-md-9 pt-3">
                    <span className="fs-16">{compra.productos[0].nombre}</span>
                    <br />
                    <span className="fs-12" style={{ "whiteSpace": "pre-wrap" }}>
                      {
                        compra.estado_despacho == "Venta Recibida" && (
                          <>
                            ESTADO: {" "}<i className="fa-solid fa-circle-pause text-primary"></i> {" "}{compra.estado_despacho}
                          </>
                        )}
                      {
                        compra.estado_despacho == "En Preparación" && (
                          <>
                            ESTADO: {" "}<i className="fa-solid fa-circle-pause text-danger"></i> {" "}{compra.estado_despacho}
                          </>
                        )}
                      {
                        compra.estado_despacho == "En Camino" && (
                          <>
                            ESTADO: {" "}<i className="fa-solid fa-truck text-warning"></i> {" "}{compra.estado_despacho}
                          </>
                        )}
                      {
                        compra.estado_despacho == "Listo para retiro" && (
                          <>
                            ESTADO: {" "}<i className="fa-solid fa-person-running text-danger"></i> {" "}{compra.estado_despacho}
                          </>
                        )}
                      {
                        compra.estado_despacho == "Entregada" && (
                          <>
                            ESTADO: {" "}<i className="fa-solid fa-circle-check text-success"></i> {" "}{compra.estado_despacho}
                          </>
                        )}
                      {
                        compra.estado_despacho == "Entregado en tienda" && (
                          <>
                            ESTADO: {" "}<i className="fa-solid fa-circle-check text-success"></i> {" "}{compra.estado_despacho}
                          </>
                        )}
                    </span>
                  </div>
                  <div className="col-md-1 pt-3 text-end">
                    <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnCelesteDreamtec"
                      onClick={() => { mostrarDetalleCompra(compra.productos) }}><i className="fa-solid fa-eye"></i>
                    </button>
                  </div>
                </div>
              </div>


              {/* Version Movil */}
              <>
                <div className="container border bg-light mt-2 rounded-top text-secondary d-xs-block d-md-none" key={compra.id}>
                  <div className="row p-2">
                    <div className="col-6 mb-2">
                      <span className="fs-12">FECHA DEL PEDIDO</span>
                      <br />
                      <span className="fs-14"><strong>{compra.fecha}</strong></span>
                    </div>
                    <div className="col-6">
                      <span className="fs-12">TOTAL</span>
                      <br />
                      <span className="fs-14"><strong>{"$" + compra.total.toLocaleString("es-CL")}</strong></span>
                    </div>
                    <div className="col-6">
                      <span className="fs-12">{compra.direccion == "Dreamtec Tienda" ? "ENTREGAR A" : "ENVIAR A"}</span>
                      <br />
                      <span className="fs-14"><strong>{compra.usuario.replace(".zzzzz.", "")}</strong></span>
                    </div>
                    <div className="col-6">
                      <span className="fs-12">NUMERO PEDIDO: {compra.id}</span>
                      <br />
                      {compra.link_boleta && compra.link_boleta != "" ? <span><a href={compra.link_boleta} target="_blank" style={{ textDecoration: "none" }}>Ver Comprobante</a></span> : ""}

                    </div>
                  </div>
                </div>


                <div className="container border mb-4 rounded-bottom d-xs-block d-md-none" key={compra.id + "-" + compra.id}>
                  <div className="row px-2">
                    <div className="col-md-2 text-center">
                      <img loading="lazy" alt={compra.productos[0].nombre} title={compra.productos[0].nombre} src={process.env.REACT_APP_FRONT_URL + compra.productos[0].foto} className="img-fluid" />
                    </div>
                    <div className="col-md-9 pt-3">
                      <span className="fs-16">{compra.productos[0].nombre}</span>
                      <br />
                      <span className="fs-12" style={{ "whiteSpace": "pre-wrap" }}>
                        {
                          compra.estado_despacho == "Venta Recibida" && (
                            <>
                              ESTADO: {" "}<i className="fa-solid fa-circle-pause text-primary"></i> {" "}{compra.estado_despacho}
                            </>
                          )}
                        {
                          compra.estado_despacho == "En Preparación" && (
                            <>
                              ESTADO: {" "}<i className="fa-solid fa-circle-pause text-danger"></i> {" "}{compra.estado_despacho}
                            </>
                          )}
                        {
                          compra.estado_despacho == "En Camino" && (
                            <>
                              ESTADO: {" "}<i className="fa-solid fa-truck text-warning"></i> {" "}{compra.estado_despacho}
                            </>
                          )}
                        {
                          compra.estado_despacho == "Entregada" && (
                            <>
                              ESTADO: {" "}<i className="fa-solid fa-circle-check text-success"></i> {" "}{compra.estado_despacho}
                            </>
                          )}
                        {
                          compra.estado_despacho == "Entregado en tienda" && (
                            <>
                              ESTADO: {" "}<i className="fa-solid fa-circle-check text-success"></i> {" "}{compra.estado_despacho}
                            </>
                          )}
                      </span>
                    </div>
                    <div className="row py-3 justify-content-center">
                      <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnCelesteDreamtec col-4"
                        onClick={() => { mostrarDetalleCompra(compra.productos) }}><i className="fa-solid fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </>

          ))
        ) : (
          <div>
            {" "}
            <span className="fw-bold mt-3">
              {" "}
              Cuando realices una compra podrás hacer seguimiento de
              esta en esta sección.
            </span>
            <p className="mt-3">
              Revisa nuestro catálogo y aprovecha todos los productos
              y ofertas que tenemos disponibles para ti.
            </p>
            <button
              onClick={() => {
                navigate("/");
              }}
              className="btn-borde-turq rounded-pill text-turq py-1 px-4 mb-3"
            >
              Ir a comprar
            </button>
          </div>
        )}
      </div>
    </div>

  );
};

export default ComprasAnteriores;
