/**
 * Componente Header:
 * 
 * acá se muestra el header del Sitio Web
 * 
 * @module ComponenteHeaderjsx
 */


import { useState, useEffect, useRef } from "react";
import "./header.css";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { useDispatch, useSelector } from "react-redux";
//import { setBooleanCategoria } from "../../redux/esCategoriaSlice";

import {
  NavCategorias,
  IngresarDirecion,
  Login,
  Carrito,
  Buscador,
  ModalAgregarInvitado,
  Retiraotro,
} from "../index";

const Header = () => {

  /**
* @constant
* @name categoriasVisible
* @type {boolean}
* 
* */
  const [categoriasVisible, setCategoriasVisible] = useState(false);


  /**
* @constant
* @name categoriasVisibleMovil
* @type {boolean}
* 
* */
  const [categoriasVisibleMovil, setCategoriasVisibleMovil] = useState(false);

  /**
* @constant
* @name categorias
* @type {Array}
* 
* */
  const [categorias, setCategorias] = useState([]);

  /**
* @constant
* @name categoriaGamer
* @type {Array}
* 
* */
  const [categoriaGamer, setCategoriaGamer] = useState([]);

  /**
* @constant
* @name categoriaHomeOffice
* @type {Array}
* 
* */
  const [categoriaHomeOffice, setCategoriaHomeOffice] = useState([]);


  /**
* @constant
* @name categoriaSmartHome
* @type {Array}
* 
* */
  const [categoriaSmartHome, setCategoriaSmartHome] = useState([]);


  /**
* @constant
* @name nombre
* @type {string}
* 
*/
  const [nombre, setNombre] = useState("");

  /**
* @constant
* @name token
* @type {string}
* 
*/
  const token = sessionStorage.getItem("accessTokenWeb");
  const [popup, setPopup] = useState(() => {
    const storedPopup = sessionStorage.getItem('popup');
    return storedPopup ? parseInt(storedPopup, 10) : 2; // 1 = el popup aparece, 2 = está siempre escondido
  });

  const cierraPopup = () => {
    setPopup(2);
    sessionStorage.setItem("popup", 2);
  }

  const loc = useLocation();
  const isPaso1Page =
    loc.pathname === "/paso1" ||
    loc.pathname === "/paso2" ||
    loc.pathname === "/paso3" ||
    loc.pathname === "/confirmed";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const divRefMovil = useRef(null);

  const nombreComuna = useSelector((state) => state.comunaSelected);

  //ReactGA.initialize(process.env.REACT_APP_GA4_CODE, { debug: true });
  ReactGA.initialize(process.env.REACT_APP_GA4_CODE);


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/categorias/get_all_categorias")
      .then((res) => {
        setCategorias(
          res.data.gamer_zone.concat(res.data.home_office, res.data.smart_home)
        );
        setCategoriaGamer(res.data.gamer_zone);
        setCategoriaHomeOffice(res.data.home_office);
        setCategoriaSmartHome(res.data.smart_home);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    if (!document.URL.includes("/paso2") && !document.URL.includes("/paso3")) {
      sessionStorage.removeItem("LocalTime");
      sessionStorage.removeItem("tzm");
    }

    /**
* @function
* Petición HTTP para obtener los datos del usuario logueado
* @param {string} token token de acceso
* @returns {string}
* 
*/
    if (token) {
      axios
        .get(process.env.REACT_APP_BACK_URL + "/api/usuario/get_usuario_web", config)
        .then((response) => {
          setNombre(response.data.nombre);
        })
        .catch((error) => {
          console.log(error);

          if (parseInt(token) == NaN) {
            if ((parseInt(token) < 1000000 || parseInt(token) > 99999999)) {
              sessionStorage.clear();
            }
          }
        });
    }
  });

  const toggleCategorias = () => {
    setCategoriasVisible(!categoriasVisible);
  };

  const toggleCategoriasMovil = () => {
    setCategoriasVisibleMovil(!categoriasVisibleMovil);
  };

  const categoriasStyle = {
    display: categoriasVisible ? "block" : "none",
    backgroundColor: categoriasVisible ? "white" : "",
  };

  const botonStyle = {
    backgroundColor: categoriasVisibleMovil ? "white" : "",
    color: categoriasVisibleMovil ? "black" : "white",
    borderBottom: categoriasVisibleMovil ? "none" : "",
    borderRadius: "0px",
  };

  const categoriasStyleMovil = {
    display: categoriasVisibleMovil ? "block" : "none",
    backgroundColor: categoriasVisibleMovil ? "white" : "",
  };

  const botonStyleMovil = {
    backgroundColor: categoriasVisible ? "white" : "",
    color: categoriasVisible ? "black" : "white",
    borderBottom: categoriasVisible ? "none" : "",
    borderRadius: "0px",
  };

  /**
* @function
* Metodo para validar si uso clic ahora del element atual
* @param {Element} event elemento actual
*/
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        categoriasVisible &&
        divRef.current &&
        !divRef.current.contains(event.target)
      ) {
        toggleCategorias();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [categoriasVisible]);

  /**
* @function
* Metodo para validar si uso clic ahora del element atual
* @param {Element} event elemento actual
*/
  useEffect(() => {
    const handleClickOutsideMovil = (event) => {
      if (
        categoriasVisibleMovil &&
        divRefMovil.current &&
        !divRefMovil.current.contains(event.target)
      ) {
        toggleCategorias();
      }
    };

    document.addEventListener("click", handleClickOutsideMovil);

    return () => {
      document.removeEventListener("click", handleClickOutsideMovil);
    };
  }, [categoriasVisibleMovil]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const checkNumber = () => {
    let numeros = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    for (let i = 0; i < token.length; i++) {
      if (!numeros.includes(token.charAt(i)))
        return false;
    }
    return true;
  };

  const validaToken = () => {
    if (token != null) {
      if (checkNumber()) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  };

  const popUpRedirect = () => {
    cierraPopup();
    window.open("https://dreamtec.cl/home-office/navidad", "_blank");
  };

  return (
    <>
      <header
        className={`header-fixed bg-black w-100 ${!isPaso1Page ? "d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block" : ""}`}
        id="myHeader"
      >
        {popup == 1 && (
          <>
            <div className="popup-bg">
              <div className="popup-img">
                <i class="fa-solid fa-x popup-x border border-white rounded-circle p-1" onClick={cierraPopup}></i>
                <img loading="eager" src={"https://dreamtec.cl/uploads/popups/navidad-2024.png"} alt="cargando" title="cargando" className="img-fluid" role="button" onClick={popUpRedirect} />
              </div>
            </div>
          </>
        )}
        
        <nav className="navbar navbar-expand-lg navbar-dark py-4">
          <div className="container">
            <a className="text-center" href="/">
              <img
                loading="eager"
                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/logo-header.png"}
                className="img-fluid "
                alt="Logo header Dreamtec"
                title="Logo header Dreamtec"
                height={51}
                width={190}
              />
            </a>

            {!isPaso1Page && (
              <>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#ingresar-domicilio"
                  aria-label="Ingresa tu domicilio"
                  className="btn text-white py-0 px-2"
                >
                  <i className="fa-sharp fa-solid fa-location-dot"></i>
                  &nbsp;&nbsp;
                  {nombreComuna ? (
                    <span>
                      Envio a <br />
                      {nombreComuna}
                    </span>
                  ) : (
                    <span id="inner_domicilio">
                      Ingresa tu
                      <br class="d-none d-sm-none d-md-none d-lg-block d-lx-block d-xxl-block" /> domicilio
                    </span>
                  )}
                </button>

                <div className="col-xl-3 col-lg-2 col-md-4 col-sm-12 col-12">
                  <Buscador />
                </div>

                <div className="position-relative mi-divmovil d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block mx-1">
                  <button
                    className="mi-div-movil btn text-white fs-14 p-0 px-1"
                    id="btn_todas_las_categorias"
                    type="button"
                    onClick={toggleCategorias}
                    style={botonStyle}
                    ref={divRef}
                  >
                    <i
                      className="col-12 fa-solid fa-bars"
                      style={botonStyle}
                    ></i>
                    <span style={botonStyle}>Categorías</span>
                  </button>

                  {categoriasVisible && (
                    <div
                      className="bg-white p-1 div-listado-categorias"
                      id="div_listado_categorias"
                      style={categoriasStyle}
                    >
                      {categoriaGamer.map((categoria, index) => (
                        <a
                          className="text-decoration-none text-dark"
                          onClick={() => {
                            location.href = `/gamer-zone/${categoria}`
                          }}
                          key={index}
                        >
                          <div className="row p-0 m-0">
                            <div className="col-lg-6 col-md-6 col-6 texxt-start fs-14 p-2 li-categoria">
                              {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                            </div>
                            <div className="col-lg-3 col-md-3 col-3">
                              <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-gamer-zone.png"} alt="Logo Gamer Zone" title="Logo Gamer Zone" height={30} width={30} className="img-fluid" loading="lazy" />
                            </div>
                          </div>
                        </a>
                      ))}

                      {categoriaHomeOffice.map((categoria, index) => (
                        <a
                          className="text-decoration-none text-dark"
                          onClick={() => {
                            location.href = `/home-office/${categoria}`
                          }}
                          key={index}
                        >
                          <div className="row p-0 m-0">
                            <div className="col-lg-6 col-md-6 col-6 texxt-start fs-14 p-2 li-categoria">
                              {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                            </div>
                            <div className="col-lg-3 col-md-3 col-3">
                              <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-home-office.png"} alt="Logo Home Office" title="Logo Home Office" height={30} width={30} className="img-fluid" loading="lazy" />
                            </div>
                          </div>
                        </a>
                      ))}

                      {categoriaSmartHome.map((categoria, index) => (
                        <a
                          className="text-decoration-none text-dark"
                          onClick={() => {
                            location.href = `/smart-home/${categoria}`
                          }}
                          key={index}
                        >
                          <div className="row p-0 m-0">
                            <div className="col-lg-6 col-md-6 col-6 texxt-start fs-14 p-2 li-categoria">
                              {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                            </div>
                            <div className="col-lg-3 col-md-3 col-3">
                              <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-smart-home.png"} alt="Logo Smart Home" title="Logo Smart Home" height={30} width={30} className="img-fluid" loading="lazy" />
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  )}
                </div>

                <div className="row align-items-center justify-content-center">
                  <div
                    className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mi-divmovil d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block"
                    style={{ cursor: "pointer" }}
                  >
                    <a href="https://wa.me/56942428870"
                      className="text-decoration-none">
                      <div className="row">

                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 px-0 text-center mb-2">

                          <img
                            loading="eager"
                            src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono-boton-header-tel.png"}
                            className="icono-header-circular"
                            alt="Teléfono de contacto"
                            title="Teléfono de contacto"
                          />

                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 lh-1 text-white">
                          VENTAS <br />
                          <a
                            href="tel:+56229257620"
                            className="text-turq fs-14 text-decoration-none"
                          >
                            +56229257620
                          </a>
                        </div>

                      </div>
                    </a>
                  </div>

                  <div className="col-1 borde-blanco-1px d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block"></div>
                  <div
                    className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12"
                    style={{ cursor: "pointer" }}
                  >
                    {(validaToken() == true) ? (
                      <div className="dropdown">
                        <button
                          className="row justify-content-center bg-transparent border-0 dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-center px-2 mb-2">
                            <img
                              loading="lazy"
                              src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono-boton-header-usuario.png"}
                              className="icono-header-circular"
                              alt="Usuario"
                              title="Usuario"
                            />
                          </div>
                          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 text-white lh-1 px-1">
                            <span className="text-white">
                              Hola! <br /> {capitalizeFirstLetter(nombre.split(" ")[0])}
                            </span>
                          </div>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item" href="/perfil">
                            Perfil
                          </a>
                          <a className="dropdown-item" href="/compras">
                            Mis Compras
                          </a>
                          <a
                            onClick={() => {
                              sessionStorage.clear();
                            }}
                            className="dropdown-item"
                            href="/"
                          >
                            Cerrar Sesión
                          </a>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="row justify-content-center bg-transparent border-0"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-inicio-sesion"
                        aria-label="Iniciar Sesión"
                      >
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-center px-2 mb-2">
                          <img
                            loading="eager"
                            src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono-boton-header-usuario.png"}
                            className="icono-header-circular"
                            alt="Usuario"
                            title="Usuario"
                          />
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 text-white lh-1">
                          <span className="">Hola!</span>
                          <br />
                          <span className="text-turq">Entrar</span>
                        </div>
                      </button>
                    )}
                  </div>
                </div>

                <div className="row" style={{ cursor: "pointer" }}>
                  <Carrito />
                </div>
              </>
            )}
          </div>
        </nav>

        {!isPaso1Page && <NavCategorias />}
      </header >

      <Login />

      <ModalAgregarInvitado />

      <Retiraotro />

      <IngresarDirecion />

      {/* header version movil max 1000px */}

      {
        !isPaso1Page && (


          <header
            className="header-fixed bg-black w-100 d-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none"
            id="myHeader"
          >
            {popup == 1 && (
              <>
                <div className="popup-bg">
                  <div className="popup-img">
                    <i class="fa-solid fa-x popup-x border border-white rounded-circle p-1" onClick={cierraPopup}></i>
                    <img loading="eager" src={"https://dreamtec.cl/uploads/popups/navidad-2024.png"} alt="cargando" title="cargando" className="img-fluid" role="button" onClick={popUpRedirect} />
                  </div>
                </div>
              </>
            )}
            <div className="container">
              <nav className=" navbar-dark align-self-center">
                <div className="row justify-content-center">

                  <div className="col-lg-3 col-md-4 col-sm-3 col-3 text-center align-self-center mb-2 d-lg-none">
                    <button
                      className="navbar-toggler"
                      id="btn_todas_las_categorias_2"
                      type="button"
                      onClick={toggleCategoriasMovil}
                      ref={divRefMovil}
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>

                    {categoriasVisibleMovil && (
                      <div
                        className="bg-white p-1 div-listado-categorias"
                        id="div_listado_categorias"
                        style={categoriasStyleMovil}
                      >
                        {categoriaGamer.map((categoria, index) => (
                          <a
                            className="text-decoration-none text-dark"
                            onClick={() => {
                              location.href = `/gamer-zone/${categoria}`
                            }}
                            key={index}
                          >
                            <div className="row p-0 m-0">
                              <div className="col-lg-6 col-md-6 col-6 texxt-start fs-14 p-2 li-categoria">
                                {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                              </div>
                              <div className="col-lg-3 col-md-3 col-3">
                                <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-gamer-zone.png"} alt="Logo Gamer Zone" title="Logo Gamer Zone" height={30} width={30} className="img-fluid" loading="lazy" />
                              </div>
                            </div>
                          </a>
                        ))}

                        {categoriaHomeOffice.map((categoria, index) => (
                          <a
                            className="text-decoration-none text-dark"
                            onClick={() => {
                              location.href = `/home-office/${categoria}`
                            }}
                            key={index}
                          >
                            <div className="row p-0 m-0">
                              <div className="col-lg-6 col-md-6 col-6 texxt-start fs-14 p-2 li-categoria">
                                {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                              </div>
                              <div className="col-lg-3 col-md-3 col-3">
                                <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-home-office.png"} alt="Logo Home Office" title="Logo Home Office" height={30} width={30} className="img-fluid" loading="lazy" />
                              </div>
                            </div>
                          </a>
                        ))}

                        {categoriaSmartHome.map((categoria, index) => (
                          <a
                            className="text-decoration-none text-dark"
                            onClick={() => {
                              location.href = `/smart-home/${categoria}`
                            }}
                            key={index}
                          >
                            <div className="row p-0 m-0">
                              <div className="col-lg-6 col-md-6 col-6 texxt-start fs-14 p-2 li-categoria">
                                {categoria.charAt(0).toUpperCase() + categoria.slice(1).toLowerCase()}
                              </div>
                              <div className="col-lg-3 col-md-3 col-3">
                                <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-smart-home.png"} alt="Logo Smart Home" title="Logo Smart Home" height={30} width={30} className="img-fluid" loading="lazy" />
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-4 col-4 align-self-center text-center mb-2">
                    <a className="" href="/">
                      <img
                        loading="lazy"
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/logo-header.png"}
                        className="img-fluid logo-header-movil"
                        alt="Logo header Dreamtec"
                        title="Logo header Dreamtec"
                        height={38}
                        width={140}
                      />
                    </a>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-4 col-3 align-self-center mb-2 mt-3">
                    <div className="row justify-content-center">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-5 text-center">
                        {!token ? (
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-inicio-sesion"
                            aria-label="Iniciar Sesión"
                            className="btn fs-12 p-0 text-white"
                          >
                            <img
                              loading="lazy"
                              src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono-boton-header-usuario.png"}
                              className="icono-header-circular"
                              alt="Usuario"
                              title="Usuario"
                            />
                            <div>Entrar</div>
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className="btn fs-12 p-0 text-white"
                            >
                              <img
                                loading="lazy"
                                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono-boton-header-usuario.png"}
                                className="icono-header-circular"
                                alt="Usuario"
                                title="Usuario"
                              />
                              {/* <div className="col-lg-2 col-md-4 col-sm-4 col-3 ">
                          <span className="text-white">
                            Hola!  {capitalizeFirstLetter(nombre)}
                          </span>
                        </div> */}
                            </button>

                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a className="dropdown-item" href="/perfil">
                                Perfil
                              </a>
                              <a className="dropdown-item" href="/compras">
                                Mis Compras
                              </a>
                              <a
                                onClick={() => {
                                  sessionStorage.removeItem("accessTokenWeb");
                                }}
                                className="dropdown-item"
                                href="/"
                              >
                                Cerrar Sesión
                              </a>
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        role="button"
                        className="col-lg-2 col-md-6 col-sm-6 col-5 fs-12 text-center ml-2"
                      // onClick={() => navigate("/paso1")}
                      >
                        <Carrito />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-11 col-sm-12 col-12 align-self-center text-center">
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#ingresar-domicilio"
                      aria-label="Ingresa tu domicilio"
                      className="btn text-white px-0 py-0"
                    >
                      <i className="fa-sharp fa-solid fa-location-dot"></i>
                      &nbsp;&nbsp;
                      {nombreComuna ? (
                        <span>
                          Envio a <br />
                          {nombreComuna}
                        </span>
                      ) : (
                        <span id="inner_domicilio">
                          Ingresa tu
                          <br class="d-none d-sm-none d-md-none d-lg-block d-lx-block d-xxl-block" /> domicilio
                        </span>
                      )}
                    </button>
                  </div>

                  <div className="col-lg-4 col-md-11 col-sm-12 col-12">
                    <Buscador />
                  </div>
                </div>

              </nav>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="mb-2 px-0">
                  <NavCategorias />
                </div>
              </div>
            </div>
          </header>
        )
      }

      {/* header version movile max 1000px */}
    </>
  );
};

export default Header;
