import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const MarcajeBannersCategorias = (props) => {
  const navigate = useNavigate();

  const [mensaje, setMensaje] = useState("");
  const [msg, setMsg] = useState("");
  const [tipo, setTipo] = useState("");
  const accessToekn = sessionStorage.getItem("access");
  const [banners, setBanners] = useState([]); // Cambiado para trabajar con un array en lugar de un objeto
  const [bannerEstatico, setBannerEstatico] = useState(null); // Para el banner estático
  const [alt, setAlt] = useState({});

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        // Llamar a los dos endpoints
        const responseBanners = await axios.get(
          `${process.env.REACT_APP_BACK_URL}/api/categoria_banner/get_categorias_banners`
        );
        const responseEstatico = await axios.get(
          `${process.env.REACT_APP_BACK_URL}/api/banners/get_banner_estaticos`
        );

        // Configurar banners de categoría y banner estático
        setBanners(responseBanners.data);
        setBannerEstatico(responseEstatico.data[0]); // Usar el primer banner estático si existe

        // Inicializar estado `alt` para ambos
        const initialAlt = {};

        // Agregar alts de banners de categoría
        Object.keys(responseBanners.data).forEach((key) => {
          responseBanners.data[key].forEach((banner) => {
            initialAlt[banner.id_banner] = banner.alt || ""; // Usa el ID del banner como clave
          });
        });

        // Agregar alt para el banner estático
        if (responseEstatico.data[0]) {
          initialAlt[responseEstatico.data[0].id] = responseEstatico.data[0].alt || "";
        }

        setAlt(initialAlt);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBanners();
  }, []);

  const validateAlt = () => {
    const validarHTML = /<\/?[a-z][\s\S]*>/i;
    let isValid = true;

    Object.values(alt).forEach((value) => {
      if (validarHTML.test(value || "")) {
        setMensaje("Uno o más campos Alt contienen HTML-rojo");
        setTipo(2);
        isValid = false;
      }
    });

    return isValid;
  };

  const handleGuardar = async () => {
    if (!validateAlt()) {
      return;
    }

    // Construir los arrays de banners separados
    const bannersCategoria = [];
    Object.keys(banners).forEach((key) => {
      banners[key].forEach((banner) => {
        bannersCategoria.push({
          id: banner.id_banner,
          alt: alt[banner.id_banner] || "",
        });
      });
    });

    const bannerEstaticoPayload = bannerEstatico
      ? [
          {
            id: bannerEstatico.id,
            alt: alt[bannerEstatico.id] || "",
          },
        ]
      : [];

    try {
      // Enviar banners de categoría
      if (bannersCategoria.length > 0) {
        const dataToSendCategoria = { banners: bannersCategoria };
        const responseCategoria = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/api/marcaje/marcaje_banner_home`,
          dataToSendCategoria,
          {
            headers: {
              Authorization: `Bearer ${accessToekn}`,
              "Content-Type": "application/json",
            },
          }
        );
      }

      // Enviar banner estático
      if (bannerEstaticoPayload.length > 0) {
        const formData = new URLSearchParams();
        formData.append("id", bannerEstaticoPayload[0].id);
        formData.append("alt", bannerEstaticoPayload[0].alt);

        const responseEstatico = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/api/marcaje/marcaje_banner_estatico`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToekn}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
      }

      setMensaje("Banners actualizados correctamente-verde");
      setTipo(1);
    } catch (error) {
      console.error("Error al guardar los banners:", error.response?.data || error.message);
    }
  };

  return (
    <div>
      <AdminSidebar />
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />

      <div className="regular">
        <h1>
          Dreamtec - <span>Administrador de marcaje banners</span>
        </h1>
        <div className="shadow">
          {/* Renderizar banner estático */}
          {bannerEstatico && (
            <div>
              <div className="col-12 text-start my-3">
                <h2>BANNER SUPERIOR</h2>
              </div>
              <div className="row">
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                  <img
                    src={`${process.env.REACT_APP_FRONT_URL}${bannerEstatico.url}`}
                    width={200}
                    height={100}
                    className="img-fluid"
                    alt={
                      alt[bannerEstatico.id] || `Alt no definido para banner estático`
                    }
                  />
                </div>
                <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                  <label className="fw-bold mx-1 form-label">
                    Alt banner estático:
                  </label>
                  <input
                    className="form-control"
                    placeholder={`Alt para el banner estático`}
                    type="text"
                    value={alt[bannerEstatico.id] || ""}
                    required
                    onChange={(e) => {
                      const newAlt = { ...alt };
                      newAlt[bannerEstatico.id] = e.target.value;
                      setAlt(newAlt);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Renderizar banners de categorías */}
          {banners &&
            Object.keys(banners).map((classification, index) => (
              <div key={classification}>
                <div className="col-12 text-start my-3">
                  <h2>{`GRUPO ${index + 1}`}</h2>
                </div>
                {banners[classification].map((banner) => (
                  <div className="row" key={banner.id_banner}>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                      <img
                        src={`${process.env.REACT_APP_FRONT_URL}${banner.foto}`}
                        width={200}
                        height={100}
                        className="img-fluid"
                        alt={
                          alt[banner.id_banner] ||
                          `Alt no definido para banner ${banner.id_banner}`
                        }
                      />
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4 align-self-center">
                      <label className="fw-bold mx-1 form-label">
                        Alt banner {banner.id_banner}:
                      </label>
                      <input
                        className="form-control"
                        placeholder={`Alt para el banner ${banner.id_banner}`}
                        type="text"
                        value={alt[banner.id_banner] || ""}
                        required
                        onChange={(e) => {
                          const newAlt = { ...alt };
                          newAlt[banner.id_banner] = e.target.value;
                          setAlt(newAlt);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}

          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-4">
              <button
                aria-label="Guardar"
                className="btnGreen w-100"
                type="button"
                onClick={handleGuardar}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarcajeBannersCategorias;
