/**
 * Componente EmpresasCard:
 * 
 * acá se muestran productos especificos para ser utilizados en empresas
 * 
 * @module ComponenteEmpresasCardjsx
 */


import { useEffect, useState } from "react";
import "./novedadescards.css";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

import { useSelector, useDispatch } from "react-redux";
//import { setAddToCart } from "../../redux/AddToCartSlice";

SwiperCore.use([Navigation, Autoplay]);

const NovedadesCards = () => {

  /**
* @constant
* @name novedades
* @type {Array}
* 
* */

  const [novedades, setNovedades] = useState([]);


  /**
* @constant
* @name userAlready
* @type {string}
* 
* */

  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  const codigo_envio = useSelector((state) => state.tipoEnvio);
  const fecha_entrega = useSelector((state) => state.fechaEntrega);
  const addToCart = useSelector((state) => state.addToCart);
  const dispatch = useDispatch();


  /**
* @function
* Petición HTTP que obtiene los productos relacionados con empresas
*@returns {Array}
* 
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/categoria/carrusel-dreamtec-empresarial`)
      .then((res) => {
        setNovedades(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  

  return (
    <div className="">
      <Swiper
        spaceBetween={20}
        navigation
        autoplay={{ delay: 3000 }}
        breakpoints={{
          1200: {
            slidesPerView: 2,
          },
          1199: {
            slidesPerView: 1,
          },
        }}
      >
        {novedades.map((novedad) => (
          <SwiperSlide key={novedad.id}>
            {/* Version XXL */}
            <div className="border border-dark-subtle mb-5 text-center px-2 pb-4 pt-3 rounded-3 d-xs-none d-sm-none d-md-none d-lg-none d-xl-none d-xxl-block" style={{ height: "387px" }}>
              <div className="row d-flex justify-content-end">
                <div className="tag-prod-skunov text-center mx-2 w-auto px-2">
                  SKU {novedad.sku}
                </div>
              </div>

              <div className="row">
                <div className="fixedwidth mt-2">
                  <a
                    className="text-black"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + `${novedad.foto_1}`}
                      alt={novedad.alt_foto_1}
                      title={novedad.nombre}
                      className="imgwidth border-0"
                      style={{ minHeight: "120px", maxHeight: "120px" }}
                    />
                  </a>
                </div>
                <div className="container">
                  <div className="row text-center">
                    <p className="nombre-marca mx-auto">
                      <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                      {novedad.marca}
                    </p>
                  </div>
                  <div className="row text-center">
                    <h3 className="text-black mb-0 mt-0 nombre-tarj-prod fw-normal">
                      <a
                        className="text-black"
                        href={`/${novedad.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        {novedad.nombre}
                      </a>
                    </h3>
                  </div>
                  <div className="row text-center">
                    <p className="fs-5 fw-bold my-1 text-black">
                      {novedad.precio_descuento.toLocaleString("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      })}
                    </p>
                  </div>
                  <div className="container">
                    <div className="row mb-2">
                      {/* {codigo_envio === 1 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )} */}

                      {/* {codigo_envio === 2 && (
                        <>
                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                              {" "}
                              {fecha_entrega}
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                              Envio Gratis
                            </div>
                          </div>
                        </>
                      )} */}
                      {/* {codigo_envio === 3 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )}
                      {codigo_envio === 4 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                            {" "}
                            Retiro en tienda
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>

                  {/* <a
                    className="text-white"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra">

                      Ver detalles

                    </button>
                  </a> */}
                  {/* <button
                    onClick={() => handleAddToCart(novedad.sku)}
                    className="bg-azul text-white rounded-pill px-2 py-1 border-0 fs-14 btn-azul-hover btn-sombra"
                  >
                    <img
                      className="imgcarrito"
                      src={imgcarrito}
                      alt="imagen carrito"
                    />{" "}
                    Añadir
                  </button> */}
                </div>
              </div>

              <div className="novedad-info"></div>
            </div>

            {/* Version XL */}
            <div className="border border-dark-subtle mb-5 text-center px-2 pb-4 pt-3 rounded-3 d-xs-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-none" style={{ height: "333px" }}>
              <div className="row d-flex justify-content-end">
                <div className="tag-prod-skunov text-center mx-2 w-auto px-2">
                  SKU {novedad.sku}
                </div>
              </div>

              <div className="row">
                <div className="mt-2">
                  <a
                    className="text-black"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + `${novedad.foto_1}`}
                      alt={novedad.alt_foto_1}
                      title={novedad.nombre}
                      className="imgwidth border-0"
                      style={{ minHeight: "80px", maxHeight: "80px" }}
                    />
                  </a>
                </div>
                <div className="container">
                  <div className="row text-center">
                    <p className="nombre-marca mx-auto">
                      <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                      {novedad.marca}
                    </p>
                  </div>
                  <div className="row text-center">
                    <h3 className="text-black mb-0 mt-0 nombre-tarj-prod fw-normal">
                      <a
                        className="text-black"
                        href={`/${novedad.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        {novedad.nombre}
                      </a>
                    </h3>
                  </div>
                  <div className="row text-center">
                    <p className="fs-5 fw-bold mb-2 mt-2 text-black">
                      {novedad.precio_descuento.toLocaleString("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      })}
                    </p>
                  </div>
                  <div className="container">
                    <div className="row mb-2">
                      {/* {codigo_envio === 1 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )}

                      {codigo_envio === 2 && (
                        <>
                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                              {" "}
                              {fecha_entrega}
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                              Envio Gratis
                            </div>
                          </div>
                        </>
                      )}
                      {codigo_envio === 3 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )}
                      {codigo_envio === 4 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                            {" "}
                            Retiro en tienda
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>

                  {/* <a
                    className="text-white"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra">

                      Ver detalles

                    </button>
                  </a> */}
                </div>
              </div>



              <div className="novedad-info"></div>
            </div>


            {/* Version LG */}
            <div className="border border-dark-subtle mb-5 text-center px-2 pb-4 pt-2 rounded-3 d-xs-none d-sm-none d-md-none d-lg-block d-xl-none d-xxl-none" style={{ height: "278px" }}>
              <div className="row d-flex justify-content-end">
                <div className="tag-prod-skunov text-center mx-2 w-auto px-2">
                  SKU {novedad.sku}
                </div>
              </div>

              <div className="row">
                <div className="mt-2">
                  <a
                    className="text-black"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + `${novedad.foto_1}`}
                      alt={novedad.alt_foto_1}
                      title={novedad.nombre}
                      className="imgwidth border-0"
                      style={{ minHeight: "50px", maxHeight: "50px" }}
                    />
                  </a>
                </div>
                <div className="container">
                  <div className="row text-center">
                    <p className="nombre-marca mx-auto">
                      <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                      {novedad.marca}
                    </p>
                  </div>
                  <div className="row text-center">
                    <h3 className="text-black mb-0 mt-0 nombre-tarj-prod fw-normal">
                      <a
                        className="text-black"
                        href={`/${novedad.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        {novedad.nombre}
                      </a>
                    </h3>
                  </div>
                  <div className="row text-center">
                    <p className="fs-5 fw-bold my-0 text-black">
                      {novedad.precio_descuento.toLocaleString("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      })}
                    </p>
                  </div>
                  <div className="container">
                    <div className="row mb-3">
                      {/* {codigo_envio === 1 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )}

                      {codigo_envio === 2 && (
                        <>
                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                              {" "}
                              {fecha_entrega}
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                              Envio Gratis
                            </div>
                          </div>
                        </>
                      )}
                      {codigo_envio === 3 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )}
                      {codigo_envio === 4 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                            {" "}
                            Retiro en tienda
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>

                  {/* <a
                    className="text-white"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra">

                      Ver detalles

                    </button>
                  </a> */}
                </div>
              </div>



              <div className="novedad-info"></div>
            </div>

            {/* Version MD o menos */}
            <div className="border border-dark-subtle mb-5 text-center px-2 pb-4 pt-2 rounded-3 d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none" >
              <div className="row d-flex justify-content-end">
                <div className="tag-prod-skunov text-center mx-2 w-auto px-2">
                  SKU {novedad.sku}
                </div>
              </div>

              <div className="row">
                <div className="mt-2">
                  <a
                    className="text-black"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + `${novedad.foto_1}`}
                      alt={novedad.alt_foto_1}
                      title={novedad.nombre}
                      className="imgwidth border-0"
                    />
                  </a>
                </div>
                <div className="container">
                  <div className="row text-center">
                    <p className="nombre-marca mx-auto">
                      <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                      {novedad.marca}
                    </p>
                  </div>
                  <div className="row text-center">
                    <h3 className="text-black mb-0 mt-0 nombre-tarj-prod fw-normal">
                      <a
                        className="text-black"
                        href={`/${novedad.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        {novedad.nombre}
                      </a>
                    </h3>
                  </div>
                  <div className="row text-center">
                    <p className="fs-5 fw-bold my-0 text-black">
                      {novedad.precio_descuento.toLocaleString("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      })}
                    </p>
                  </div>
                  <div className="container">
                    <div className="row mb-4">
                      {/* {codigo_envio === 1 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )}

                      {codigo_envio === 2 && (
                        <>
                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                              {" "}
                              {fecha_entrega}
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                              Envio Gratis
                            </div>
                          </div>
                        </>
                      )}
                      {codigo_envio === 3 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-success">
                            {" "}
                            {fecha_entrega}
                          </div>
                        </div>
                      )}
                      {codigo_envio === 4 && (
                        <div className="col-12">
                          <div className="tag-envio-gratis text-center rounded-3 text-white bg-secondary">
                            {" "}
                            Retiro en tienda
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>

                  {/* <a
                    className="text-white"
                    href={`/${novedad.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="bg-turq text-white rounded-pill mbtn px-3 py-1 border-0 fs-14 btn-turq-hover btn-sombra">

                      Ver detalles

                    </button>
                  </a> */}
                </div>
              </div>



              <div className="novedad-info"></div>
            </div>

          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default NovedadesCards;
