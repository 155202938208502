/**
 * Componente RetiraOtro:
 * 
 * acá se muestra el modal de la otra persona quien retira
 * 
 * @module ComponenteRetiraOtrojsx
 */

import { useState } from "react";
import axios from "axios";

const Retiraotro = () => {


  /**
* @constant
* @name nombre
* @type {string}
* 
* */
  const [nombre, setNombre] = useState("");


  /**
* @constant
* @name apellido
* @type {string}
* 
* */

  const [apellido, setApellido] = useState("");

  /**
* @constant
* @name rut
* @type {string}
* 
* */
  const [rut, setRut] = useState("");

  /**
* @constant
* @name correo
* @type {string}
* 
* */
  const [correo, setCorreo] = useState("");

  /**
* @constant
* @name telefono
* @type {string}
* 
* */
  const [telefono, setTelefono] = useState("");

  /**
* @function
* Petición HTTP para guardar los datos de la persona quien retira
* @param {string} nombre Nombre de la persona
* @param {string} apellido Apellido de la persona
* @param {string} email Correo de la persona
* @param {string} telefono Telefono de la persona
* @param {string} Rut Telefono de la persona
*/
  const handleGuardar = (event) => {
    event.preventDefault();

    axios
      .post(
        process.env.REACT_APP_BACK_URL + "/api/cliente_retiro/register_cliente_retiro",
        {
          nombre: nombre,
          apellido: apellido,
          email: correo,
          telefono: telefono,
          rut: rut,
        }
      )
      .then((response) => {
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    
    <div
      className="modal fade"
      id="modal-retira-otro"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btn_closeidmodal"
            ></button>
          </div>
          <div className="modal-body pt-0">
            <div className="container mb-3">
              <div className="row">
                <div className="col-12 text-center fw-bold text-dark">

                  Agrega los datos de la persona que retirará tu compra.

                </div>
              </div>
            </div>
            <form onSubmit={handleGuardar}>
              <div className="mb-3">
                <label htmlFor="nombre" className="form-label">
                  Nombre:<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  placeholder="Ingresa nombre"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="apellido" className="form-label">
                  Apellido:<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                  placeholder="Ingresa apellido"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="rut" className="form-label">
                  RUT (sin puntos y con guión):<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill"
                  value={rut}
                  onChange={(e) => setRut(e.target.value)}
                  placeholder="Ej: 16926295-0"
                  required
                  minLength={9}
                  maxLength={10}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="correo" className="form-label">
                  Correo:<span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control rounded-pill"
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                  placeholder="Ingresa email"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="telefono" className="form-label">
                  Teléfono:<span className="text-danger">*</span>
                </label>
                <input
                  type="tel"
                  className="form-control rounded-pill"
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                  placeholder="Ingresa teléfono (+569...)"
                  required
                  maxLength={12}
                  minLength={12}
                />
              </div>
              <div className="modal-footer border-top-0">
                <button
                  type="submit"
                  className="btn w-100 bg-turq btn-turq-hover py-2 text-white rounded-pill"
                  data-bs-dismiss="modal"
                  aria-label="Guardar datos"
                  disabled={!telefono}
                >
                  Guardar datos
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Retiraotro;
