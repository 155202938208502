/**
 * Página Pago 2:
 * 
 * acá se muestra el paso 2 del carrito
 * 
 * @module PaginaPaso2jsx
 */

import { useEffect, useState, useRef } from "react";
import "./paso2.css";
import axios from "axios";
import ReactGA from "react-ga4";

import { useSelector, useDispatch } from "react-redux";
import { setAddToCart } from "../redux/AddToCartSlice";
import { setCheckStock } from "../redux/checkStockSlice";

import { useNavigate } from "react-router-dom";

import { ModalEditarDireccion } from "../components";
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import Modal from 'react-bootstrap/Modal';
import crypto from 'crypto-js';

const Paso2 = () => {

  /**
* @constant
* @name isDomicilioSelected
* @type {boolean}
* 
*/

  const [isDomicilioSelected, setDomicilioSelected] = useState(true);

  /**
* @constant
* @name isTiendaSelected
* @type {boolean}
* 
*/
  const [isTiendaSelected, setTiendaSelected] = useState(true);

  /**
* @constant
* @name userAlready
* @type {string}
* 
*/
  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  /**
* @constant
* @name usuarioId
* @type {string}
* 
*/
  const [usuarioId, setUsuarioId] = useState("");

  /**
* @constant
* @name productos
* @type {string}
* 
*/
  const [productos, setProductos] = useState("");

  /**
* @constant
* @name idProducto
* @type {string}
* 
*/
  const [idProducto, setIdProducto] = useState("");

  /**
* @constant
* @name idComuna
* @type {string}
* 
*/
  const [idComuna, setIdComuna] = useState("");


  /**
* @constant
* @name envio
* @type {string}
* 
*/
  const [envio, setEnvio] = useState("");


  /**
* @constant
* @name fechaEntrega
* @type {string}
* 
*/
  const [fechaEntrega, setFechaEntrega] = useState("");

  /**
* @constant
* @name iva
* @type {string}
* 
*/
  const [iva, setIva] = useState("");

  /**
* @constant
* @name total
* @type {string}
* 
*/
  const [total, setTotal] = useState("");

  /**
* @constant
* @name totalConEnvio
* @type {string}
* 
*/
  const [totalConEnvio, setTotalConEnvio] = useState("");

  /**
* @constant
* @name envio2
* @type {string}
* 
*/
  const [envio2, setEnvio2] = useState("");

  /**
* @constant
* @name fechaEntrega2
* @type {string}
* 
*/
  const [fechaEntrega2, setFechaEntrega2] = useState("");

  /**
* @constant
* @name totalConEnvio2
* @type {string}
* 
*/
  const [totalConEnvio2, setTotalConEnvio2] = useState("");


  /**
* @constant
* @name direccion
* @type {string}
* 
*/
  const [direccion, setDireccion] = useState("");

  /**
* @constant
* @name region
* @type {string}
* 
*/
  const [region, setRegion] = useState("");


  /**
* @constant
* @name comuna
* @type {string}
* 
*/
  const [comuna, setComuna] = useState("");

  /**
* @constant
* @name comunaId
* @type {string}
* 
*/
  const [comunaId, setComunaId] = useState("");

  /**
* @constant
* @name primeraVez
* @type {string}
* 
*/
  const [primeraVez, setPrimeraVez] = useState("aX7bui9ojkmJksoe8");

  /**
* @constant
* @name showAgregado
* @type {boolean}
* 
*/
  const [showAgregado, setShowAgregado] = useState(false);

  /**
* @constant
* @name mensajeError
* @type {string}
* 
*/
  const [showPregunta, setShowPregunta] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [mensajePregunta, setMensajePregunta] = useState("");


  const addToCart = useSelector((state) => state.addToCart);
  const checkStock = useSelector((state) => state.checkStock);
  const tipoEnvio = useSelector((state) => state.tipoEnvio);
  const begin_check = useRef(0);


  /**
  * @constant
  * @name marcado
  * @type {number}
  * 
  */
  const [marcado, setMarcado] = useState(0);
  const hasSelected = useRef(0);


  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }
  const handleShowPregunta = () => setShowPregunta(true);
  const handleClosePregunta = () => {
    setShowPregunta(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();



  const handleDomicilioChange = () => {
    setDomicilioSelected(true);
    setTiendaSelected(false);
    setEnvio2("");
    setFechaEntrega2("");
    setTotalConEnvio2("");
    hasSelected.current = 1;
  };

  const handleTiendaChange = () => {
    setDomicilioSelected(false);
    setTiendaSelected(true);
    setEnvio2("Retiro en tienda");
    setFechaEntrega2("Retiro en tienda");
    setTotalConEnvio2(totalConEnvio - envio);
    hasSelected.current = 1;
  };

  useEffect(() => {
    if (sessionStorage.getItem("accessTokenWeb") === null) {
      sessionStorage.clear();
      navigate("/");
    }

    if (userAlready) {
      let d = new Date();
      let time = d.getTime();
      if (!sessionStorage.getItem("LocalTime"))
        sessionStorage.setItem("LocalTime", time);
      setUserAlready(sessionStorage.getItem("IDusuario"));


      /**
* @function
* Petición HTTP que trae los datos del usuario
* @param {string} userAlready Id del usuario 
* @param {number} comunaId Id de la comuna
*/

      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/usuario/get_usuario/${userAlready}`)
        .then((res) => {
          setDireccion(res.data.direccion.direccion);
          setRegion(res.data.direccion.region);
          setComuna(res.data.direccion.comuna);
          setComunaId(res.data.direccion.comuna_id)

          axios
            .get(
              process.env.REACT_APP_BACK_URL + `/api/compra/get_resumen_compra/${userAlready}/${res.data.direccion.comuna_id}?zev=${primeraVez}`
            )
            .then((res) => {

              setIva(res.data.normal.iva);
              setTotal(res.data.normal.total);
              setEnvio(res.data.normal.envio);
              setTotalConEnvio(res.data.normal.total_con_envio);
              setFechaEntrega(res.data.normal.fecha_entrega);
              if (primeraVez == "aX7bui9ojkmJksoe8")
                setPrimeraVez("lk8r93jEhd7jdd8SX")
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });

      /**
* @function
* Petición HTTP que trae el detalle del carro
* @param {string} userAlready Id del usuario 
*/

      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/carrocompras/get_carrocompras/${userAlready ? userAlready : usuarioId
          }`
        )
        .then((res) => {
          setProductos(res.data.productos);
          beginCheckoutGa4(userAlready);
          if (res.data.productos.length === 0) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userAlready, addToCart, tipoEnvio]);


  /**
* @function
* Petición HTTP que guardar la direccion como retiro en tienda
* @param {string} userAlready Id del usuario 
* @param {string} retiro_en_tienda String que es utilizado para validar que es retiro en tienda
*/

  const handleRetiroTienda = () => {
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/direccion/register_direccion`, {
        usuario: userAlready ? userAlready : usuarioId,
        retiro_en_tienda: "Si",
      })
      .then((response) => {
        dispatch(setTipoEnvio(1));
        setMarcado(1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
* @function
* Petición HTTP que guardar la dirección
* @param {string} userAlready Id del usuario 
* @param {number} comunaId Id de la comuna
*/
  const handleDespacho = () => {
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/direccion/register_direccion`, {
        usuario: userAlready ? userAlready : usuarioId,
        comuna: comunaId,
      })
      .then((response) => {
        dispatch(setTipoEnvio(2));
        setMarcado(2);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  /**
* @function
* Petición HTTP que sirve para cambiar la cantidad de productos con el mismo sku
* @param {string} sku sku del producto
* @param  event Evento Js
*/
  const handleAddToCart = (event, sku) => {
    axios
      .post(process.env.REACT_APP_BACK_URL + "/api/carrocompras/update_carrocompras", {
        usuario: userAlready ? userAlready : usuarioId,
        sku: sku,
        cantidad: event.target.value,
      })
      .then((response) => {
        sessionStorage.setItem("IDusuario", response.data.usuario);
        setUserAlready(sessionStorage.getItem("IDusuario"));
        dispatch(setAddToCart(addToCart + 1));
        dispatch(setCheckStock(response.data.productos[0].cantidad));
        if (response.data.operacion == "suma")
          addToCartGa4(sku, response.data.cambio);
        if (response.data.operacion == "resta")
          removeFromCartGa4(response.data.id_prod, response.data.cambio)
      })
      .catch((error) => {
        console.log(error);
      });
  };



  /**
* @function
* Petición HTTP que sirve para eliminar un producto del carro
* @param {number} idproducto Id del producto a eliminar
*/
  const handleDelete = (idproducto) => {
    setIdProducto(idproducto);
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/carrocompras/delete_carrocompras`, {
        usuario: userAlready,
        producto: idproducto,
      })
      .then((res) => {
        setUserAlready(sessionStorage.getItem("IDusuario"));
        dispatch(setAddToCart(addToCart - 1));
        dispatch(setCheckStock(checkStock - 1));
        removeFromCartGa4(idproducto, res.data.todos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeFromCartGa4 = (id, cantidad) => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/ga/eliminarproductoga4?id=${id}&cantidad=${cantidad}`)
      .then((response) => {
        ReactGA.gtag("event", "remove_from_cart", {
          currency: "CLP",
          value: response.data.value,
          items: [{
            item_id: response.data.sku,
            item_name: response.data.nombre,
            item_brand: response.data.marca,
            item_category: response.data.categoria_padre,
            item_category2: response.data.categoria_hija,
            price: response.data.precio,
            quantity: response.data.cantidad
          }]
        });
      })
      .catch((error) => {
        console.log(error)
      })
  };

  const addToCartGa4 = (sku, cantidad) => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/ga/agregarproductoga4?sku=${sku}&cantidad=${cantidad}`)
      .then((response) => {
        ReactGA.gtag("event", "add_to_cart", {
          currency: "CLP",
          value: response.data.value,
          items: [{
            item_id: response.data.sku,
            item_name: response.data.nombre,
            item_brand: response.data.marca,
            item_category: response.data.categoria_padre,
            item_category2: response.data.categoria_hija,
            price: response.data.precio,
            quantity: response.data.cantidad
          }]
        });
      })
      .catch((error) => {
        console.log(error)
      })
  };

  /**
* @function
* Validación que permite saber el tipo de despacho
* @param {number} tipo numero que pertenece al tipo de despacho
*/


  const cambiarTipoDespacho = (tipo) => {
    if (parseInt(tipo) == 1) {
      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/direccion/register_direccion`, {
          usuario: userAlready ? userAlready : usuarioId,
          comuna: comunaId,
        })
        .then((response) => {
          dispatch(setTipoEnvio(2));
          setMarcado(2);
          setDomicilioSelected(true);
          setTiendaSelected(false);
          handleDomicilioChange();
          sessionStorage.setItem("tzm", crypto.SHA256(sessionStorage.getItem("LocalTime") + userAlready + sessionStorage.getItem("LocalTime")).toString());
        })
        .catch((error) => {
          console.error(error);
        });
    }
    else if (parseInt(tipo) == 2) {
      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/direccion/register_direccion`, {
          usuario: userAlready ? userAlready : usuarioId,
          retiro_en_tienda: "Si",
        })
        .then((response) => {
          dispatch(setTipoEnvio(1));
          setMarcado(1);
          setDomicilioSelected(false);
          setTiendaSelected(true);
          handleTiendaChange();
          sessionStorage.setItem("tzm", crypto.SHA256(sessionStorage.getItem("LocalTime") + userAlready + sessionStorage.getItem("LocalTime")).toString());
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("userID", userAlready);

    axios
      .post(process.env.REACT_APP_BACK_URL + "/api/carrocompras/paso2", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {

      })
      .catch((error) => {
        console.error(error.response.data.message);
      });
  }, []);


  /**
* @function
* Metodo para avanzaro no en los siguientes pasos
* 
*/

  const handleAvanzar = () => {
    if (marcado == 1 || marcado == 2)
      navigate("/paso3");
    else {
      setMensajeError("Por favor seleccione el método de entrega");
      handleShowAgregado();
    }
  };

  const preguntar = () => {
    setMensajePregunta("¿Está seguro que desea realizar su compra con retiro en tienda?");
    handleShowPregunta();
  };

  const retirarEnTienda = () => {
    handleRetiroTienda();
    cambiarTipoDespacho(2);
    handleTiendaChange();
    handleClosePregunta();
  };

  const beginCheckoutGa4 = (userId) => {
    if(begin_check.current != 0)
      return () => {};
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/ga/begincompraga4?usuario=${userId}`)
      .then((response) => {
        ReactGA.gtag("event", "begin_checkout", {
          userId: userId,
          ecommerce: {
            currency: "CLP",
            value: response.data.total,
            items: response.data.productos
          },
        });
      })
      .catch((error) => {
        console.log(error)
      })
      begin_check.current = 1;
  };

  return (
    <div>
      <Modal show={showAgregado} onHide={handleCloseAgregado} centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-3 text-center bg-black py-3">
                <i className="fa-solid fa-circle-xmark text-turq fs-1 mb-3 d-block"></i>
                <span className="fs-6 text-white mt-1" id="titulo_error">ERROR AL INGRESAR DATOS</span>
              </div>
              <div className="col-lg-10 col-md-10 col-11 mb-2 text-center">
                <p className="fs-14 text-black mb-0" id="texto_error">{mensajeError}</p>
              </div>
              <div className="col-lg-6 col-md-7 col-8 bg-turq" style={{ height: "3px" }}>
              </div>
            </div>
          </div>


        </Modal.Body>
      </Modal>

      <Modal show={showPregunta} onHide={handleClosePregunta} centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-3 text-center bg-black py-3">
                <i className="fa-solid fa-question text-turq fs-1 mb-3 d-block"></i>
                <span className="fs-6 text-white mt-1" id="titulo_error">¿RETIRO EN TIENDA?</span>
              </div>
              <div className="col-lg-10 col-md-10 col-11 mb-2 text-center">
                <p className="fs-14 text-black mb-0" id="texto_error">{mensajePregunta}</p>
                <button className="btn btn-secondary col-2 me-2" onClick={retirarEnTienda}>Si</button>
                <button className="btn btn-primary col-2" style={{ backgroundColor: "#00b4d8", border: "0" }} onClick={handleClosePregunta}>No</button>
              </div>
              <div className="col-lg-6 col-md-7 col-8 bg-turq" style={{ height: "3px" }}>

              </div>
            </div>
          </div>


        </Modal.Body>
      </Modal>

      <ModalEditarDireccion direccion={direccion} />
      <div className="container mt-5 mb-4">
        <div className="row justify-content-center">

        </div>
      </div>

      <div className="container mb-3" id="div-paso-1">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-0 text-center">
            <img
              src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/paso_2.png"}
              className="img-fluid mx-auto"
              alt="Paso 1 Opciones de envío"
              title="Paso 1 Opciones de envío"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className="container mt-5 text-start">
        <div className="row justify-content-center">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <dir className="row justify-content-evenly px-0">

              <div className="col-12 text-center mb-3 fw-bold fs-4">
                Elige la forma de recibir o retirar tu producto
              </div>

              <div
                className={`col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 position-relative text-start cursor-pointer p-3 ${isDomicilioSelected ? "round-5" : "bg-gray"
                  }`}
                onClick={() => { cambiarTipoDespacho(1); handleDespacho(); }}
              >

                <div className="row">
                  <div className="col-12">
                    <img
                      className="img-fluid mb-3"
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/despacho_domicilio.png"}
                      alt="logo retiro en tienda"
                      title="logo retiro en tienda"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <input
                      type="radio"
                      checked={(isDomicilioSelected && marcado != 0)}
                      onChange={handleDomicilioChange}
                      onClick={() => { handleDespacho() }}

                      className="cursor-pointer me-2"
                    />
                    <label
                      className={`  ${isDomicilioSelected ? "text-turq" : "bg-gray"
                        }`}
                    >
                      <span className="cursor-pointer">Recibir en mi domicilio</span>
                    </label>
                  </div>

                  <div className="col-12">

                    <span className="fw-bold cursor-pointer">{direccion}, </span>

                    <span className="fw-bold cursor-pointer">{comuna}, </span>

                    <span>{region}</span>

                  </div>

                  <div className="col-12 bottom-8 bottom-0-movil-p2">
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-editar-direccion"
                      aria-label="retira otra persona"
                      className="btn text-turq small"
                    >
                      Editar dirección
                    </button>
                  </div>

                </div>




                {!userAlready && (
                  <div className="text-start mt-5">
                    <div className="row">
                      <div className="col-md-6 col-sm-12 ">
                        <span
                          type="button"
                          id="retira_otro"
                          data-bs-toggle="modal"
                          data-bs-target="#modal-retira-otro"
                          aria-label="retira otra persona"
                          className="text-turq small mb-3"
                        >
                          Elegir dirección
                        </span>
                      </div>
                      <div className="col-6">
                        <span className="text-turq small mb-3">
                          Agregar dirección
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>


              <div
                className={`col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 p-3 cursor-pointer position-relative col-sm-none text-start cursor-pointer ${isTiendaSelected ? "round-5" : "bg-gray"
                  }`}
                onClick={() => { preguntar() }}
              >

                <div className="row">
                  <div className="col-12">
                    <img
                      className="img-fluid mb-3"
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/retiro_tienda.png"}
                      alt="logo retiro en tienda"
                      title="logo retiro en tienda"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <label
                    className={` ${isDomicilioSelected ? "text-grey" : "text-black"
                      }`}
                  >
                    <input
                      type="radio"
                      checked={(isTiendaSelected && marcado != 0)}
                      className="cursor-pointer me-2"
                    />
                    <span>Retirar en tienda, solo en: <br /> <strong>Av. Manuel Montt 1667, Local 2, Providencia, Santiago</strong></span>


                  </label>
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    id="retira_otro"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-retira-otro"
                    aria-label="retira otra persona"
                    className="btn text-turq small "
                  >
                    ¿Retira otra persona?
                  </button>
                </div>
              </div>


            </dir>
          </div>

          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-9 col-sm-8 col-12 text-start border">
            <div className="row mt-5 justify-content-start">
              <div className="col-lg-8 col-md-8 col-sm-7 col-8">
                <h2 className="fs-6 text-black fw-bolder">
                  Resumen de la compra
                </h2>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-5 col-3 align-self-center">
                <img
                  loading="lazy"
                  src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/logo-header-negro.png"}
                  className="img-fluid"
                  alt="Logo Dreamtec"
                  title="Logo Dreamtec"
                />
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
            <div className="row justify-content-start mb-2">
              {(isTiendaSelected == true && hasSelected.current == 1) ?
                <></>
                :
                <>
                  <div className="col-lg-5 col-md-5 col-5">
                    <span className="text-black fs-14">
                      <span>Valor de Envío: </span>
                    </span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end text-black fs-14 fw-bold">

                    {hasSelected.current == 0 ?
                      <>
                        Seleccione opción de entrega
                      </>
                      :
                      <>
                        {isTiendaSelected == true ?
                          <>
                            Retiro en Tienda
                          </>
                          :
                          envio == 0 ? "Gratis" : envio.toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          })
                        }
                      </>
                    }

                  </div>
                </>
              }


            </div>
            <div className="row justify-content-start mb-2">
              <div className="col-lg-5 col-md-5 col-5">
                <span className="text-black fs-14">Producto:</span>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <span className="text-black fs-14 fw-bold">
                  {total.toLocaleString("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  })}
                </span>
              </div>
            </div>
            <div className="row justify-content-start mb-2">
              <div className="col-lg-5 col-md-5 col-5">
                <span className="text-black fs-14">IVA incluído:</span>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <span className="text-black fs-14 fw-bold">
                  {iva.toLocaleString("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  })}
                </span>
              </div>
            </div>
            <div className="row justify-content-start">
              <div
                className="col-11 bg-gris-c mb-4"
                style={{ height: "1px" }}
              ></div>
            </div>
            <div className="row justify-content-start">
              <div className="col-lg-5 col-md-5 col-5">
                <span className="text-black fs-14">Total:</span>
              </div>
              <div className="col-lg-6 col-md-6 col-6 text-end">
                <span className="text-black fs-14 fw-bold">
                  {totalConEnvio2
                    ? totalConEnvio2.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })
                    : totalConEnvio.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })}
                </span>
              </div>
            </div>

            {hasSelected.current == 0 ?
              <>
                <div className="row justify-content-start mt-2 mb-5">
                  <div className="col-lg-5 col-md-5 col-5">
                    <span className="text-black fs-14">&nbsp;</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <span className="text-black fs-14 fw-bold">
                      &nbsp;
                    </span>
                  </div>
                </div>
              </>
              :
              <>
                <div className="row justify-content-center mt-5 mb-5">
                  <div className="col-lg-12 col-md-12 col-12 text-center">
                    <span className="text-black fs-14 fw-bold">
                      {fechaEntrega2 ? fechaEntrega2 : fechaEntrega}
                    </span>
                  </div>
                </div>
              </>
            }
          </div>



        </div>

        <div className="row justify-content-center mt-3">

          <div className="col-lg-8 col-md-8 col-sm-12 mb-4">
            {productos &&
              productos.map((producto, index) => (
                <div key={index} className="row py-2 mb-2 justify-content-center">
                  <hr />
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                    <div className="row align-items-center">
                      <div className="col-lg-2 col-md-3 col-7 mb-2 text-center mx-auto">
                        <img
                          src={
                            process.env.REACT_APP_FRONT_URL + producto.foto_producto
                          }
                          className="img-fluid"
                          alt={producto.name_producto}
                          title={producto.name_producto}
                          loading="lazy"
                        />
                      </div>
                      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-movil-3 mb-2 text-center-paso-2-movil">
                        <p className="lh-1 text-black fs-6 fw-light mb-0">
                          {producto.name_producto}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-8 col-sm-8 col-8 text-center px-1 align-self-center mb-2">
                    <select
                      className="form-select rounded-pill borde-turquesa fs-12"
                      value={producto.cantidad}
                      onChange={(event) => {
                        handleAddToCart(event, producto.sku_producto);
                      }}
                    >

                      {Array.from(
                        { length: producto.stock_total },
                        (_, i) => i + 1
                      ).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 text-center px-1 mb-2 align-self-center">
                    <strong>Precio Unitario:</strong> {producto.precio.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })}
                  </div>

                  <div className="col-lg-1 col-md-1 col-2 text-center align-self-center mb-2">
                    <button
                      onClick={() => {
                        handleDelete(producto.id_producto);
                      }}
                      type="button"
                      className="bg-transparent border-0"
                    >
                      <i className="far fa-trash-alt text-black fs-4"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-8 col-sm-10 col-12">
            <div className="row">
              <div className="col-12">
                <button aria-label="Continuar"
                  onClick={() => {
                    handleAvanzar()
                  }}
                  className="btn w-100 bg-turq btn-turq-hover py-2 text-white rounded-pill"
                >
                  Continuar
                </button>
              </div>
              <div className="col-12">
                <button aria-label="Volver a la tienda"
                  onClick={() => {
                    navigate("/");
                  }}
                  className="btn w-100 bg-secondary mb-3 py-2 text-white mt-4 rounded-pill"
                >
                  Volver a la tienda
                </button>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>
  );
};

export default Paso2;
